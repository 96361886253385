import './depositSecure.scss'

import walletImage from './../../../resources/images/icons/ewallet.png'
import hackerImage from './../../../resources/images/icons/hacker.png'
import standardImage from './../../../resources/images/icons/registered.png'
import systemImage from './../../../resources/images/icons/performance.png'

const DepositSecure = () => {
    const depositList = [
        {
            src: walletImage,
            title: 'Храним 96% активов на холодных кошельках',
        },
        {
            src: hackerImage,
            title: 'Противодействуем хакерским атакам с WAF',
        },
        {
            src: standardImage,
            title: 'Соблюдаем стандарты Международной группы по противодействию отмыванию доходов (FATF)',
        },
        {
            src: systemImage,
            title: 'Проверяем активы с помощью AML-системы',
        },

    ]
    return(
        <div className="deposit-secure">
            <h4 className="deposit-secure-title">Безопасность</h4>
            <p className="deposit-secure-subtitle text">Мы работаем 24/7, чтобы защитить ваши средства.</p>
            <p className="deposit-secure-text text">Мы прозрачны, поэтому регулярно проходим аудит кибербезопасности Hacken.io 
                и держим лидерство в рейтинге сертификационной платформы CER.live с наивысшим уровнем ААА.
            </p>
            <div className="deposit-secure-list">
                {
                    depositList.map((item, index) => {
                        return(
                            <li key={ index } className="deposit-secure-item">
                                <img src={ item.src } alt="secure-item" />
                                <h5 className="deposit-secure-item-title">{ item.title }</h5>
                            </li>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default DepositSecure