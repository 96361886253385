import { useEffect } from "react";
import './tradingWidget.scss';

const TradingWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [
        ["BTC", "COINBASE:BTCUSD|1D"],
        ["ETH", "BITSTAMP:ETHUSD|1D"],
        ["COINBASE:DOGEUSD|1D"],
        ["BINANCE:TRXUSDT.P|1D"],
        ["BINANCE:LTCUSDT|1D"],
        ["BINANCE:SOLUSD|1D"],
        ["BINANCE:BNBUSDT|1D"],
        ["BINANCE:AAVEUSDT|1D"],
        ["BINANCE:XLMUSDT|1D"],
        ["Microsoft", "MSFT|1D"],
        ["Google", "GOOGL|1D"],
      ],
      chartOnly: false,
      width: "100%",
      height: "100%",
      locale: "ru",
      colorTheme: "dark",
      autosize: true,
      showVolume: false,
      showMA: false,
      hideDateRanges: false,
      hideMarketStatus: false,
      hideSymbolLogo: false,
      scalePosition: "right",
      scaleMode: "Normal",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
      fontSize: "10",
      noTimeScale: false,
      valuesTracking: "1",
      changeMode: "price-and-percent",
      backgroundColor: "rgba(17, 85, 204, 0.13)",
      chartType: "line",
      dateFormat: "MM/dd/yyyy",
    });

    const tradingViewWidgetContainer = document.querySelector(".widget-trading");
    tradingViewWidgetContainer.appendChild(script);

  }, []);

  return (
        <div className="trading-widget">
          <div className="widget-trading"></div>
        </div>
  );
};

export default TradingWidget;