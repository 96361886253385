import { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Partners from '../partners/Partners';
import './footer.scss';
import logo from './../../resources/images/header/Logo.svg'
import ddosImg from './../../resources/images/footer/ddos.svg'
import sslImg from './../../resources/images/footer/ssl.svg'
import termsImg from './../../resources/images/footer/accept.png'

const Footer = () => {
    const [showButton, setShowButton] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/terms') {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }, 0);
        }
    }, [location]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        }, []);

    const handleScroll = () => {
        if (window.pageYOffset > 700) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
    };

    const handleClick = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

    return (
        <footer className="footer">
            {/* Добавляем Particles в футер */}
            <div className="container">
                <div className="footer-wrapper">
                    <Partners/>
                    <div className='footer-protect'>
                        <div className='footer-protect-row'>
                            <div className='footer-protect-row-left'>
                                <img className='' src={ddosImg} alt="ddos" />
                                <p className=''>Защита от DDoS</p>
                            </div>
                            <div className='footer-protect-row-right'>Наш веб-сайт оборудован самой современной защитой от DDoS-атак, обеспечивая непрерывную доступность онлайн-ресурсов даже в условиях массированных атак.</div>
                        </div>
                        <div className='footer-protect-row'>
                            <div className='footer-protect-row-left'>
                                <img className='' src={sslImg} alt="ssl" />
                                <p className=''>Защита SSL</p>
                            </div>
                            <div className='footer-protect-row-right'>Мы гарантируем безопасность передачи данных с помощью SSL-шифрования, обеспечивая конфиденциальность и целостность информации, передаваемой между пользователем и нашим сервером.</div>
                        </div>
                    </div>
                    <div className="footer-descr">
                        <div className='footer-operator'>
                            <div className='footer-operator-status'>
                                <iframe 
                                src="https://lottie.host/embed/0f51428e-65af-4edd-a192-c741de4547af/ODCaDTHv5u.json"
                                allowFullScreen
                                title="Operator Animation"
                                >
                                </iframe>                               
                            </div>
                            <a className="button footer-operator-button" href="https://t.me/ImperiumBroker_manager" target="_blank" rel="noreferrer">Техподдержка онлайн</a>
                        </div>
                        <div className="footer-logo">
                            <img src={logo} alt="logo"/>
                        </div>
                        <p className="footer-text text">
                            Email: 
                            <a href="mailto:imperiumbroker.info@gmail.com">imperiumbroker.info@gmail.com</a>
                        </p>
                        <p className="footer-text text">
                            © 2024 All Rights Reserved. ImperiumBroker
                        </p>
                        <NavLink to="/terms" className="footer-descr-terms text">
                            <img src={termsImg} alt="terms" />
                            Условия использования
                        </NavLink>
                    </div>
                    {showButton && (
                        <button
                        className="btn-up _icon-arrow-up"
                        onClick={handleClick}
                        ></button>
                    )}
                </div>
            </div>
        </footer>
    )
}

export default Footer;