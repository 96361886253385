import React, { useState, useEffect } from 'react';
import './profitCalculator.scss';

const ProfitCalculator = () => {
    const [amount, setAmount] = useState('');
    const [averageProfit, setAverageProfit] = useState('');

    useEffect(() => {
        if (amount) {
            const parsedAmount = parseFloat(amount);
            if (!isNaN(parsedAmount)) {
                // Рассчитываем прибыль на основе 2.75%
                const averageRate = 2.75; // Средняя процентная ставка
                const profit = (parsedAmount * (averageRate / 100)).toFixed(2);
                setAverageProfit(`${profit}`);
            } else {
                setAverageProfit('');
            }
        } else {
            setAverageProfit('');
        }
    }, [amount]);

    const handleAmountChange = (e) => {
        const value = e.target.value;
        // Проверяем, что введено число или символ "."
        if (/^\d*\.?\d*$/.test(value)) {
            setAmount(value);
        }
    };

    return (
        <div className="profit-calculator">
            <div className='profit-calculator-inner'>
                <div className='profit-calculator-top'>
                    <h3 className="profit-calculator-title title-3">Калькулятор прибыли</h3>
                    <iframe src="https://lottie.host/embed/e1da6ef9-fff0-4cfe-a5e7-e7f37325cff0/qIBp2GKhBm.json"
                    title='dollar'
                    ></iframe>
                </div>
                <div className='profit-calculator-row'>
                    <div className="profit-calculator-input-group">
                        <label htmlFor="investment-amount" className="input-label">Сумма инвестиций (в $):</label>
                        <input
                            type="text"
                            id="investment-amount"
                            placeholder="Введите сумму"
                            value={amount}
                            onChange={handleAmountChange}
                        />
                    </div>

                    <div className="profit-calculator-input-group">
                        <label htmlFor="expected-rate" className="input-label input-label">Средняя прибыль (в сутки):</label>
                        <input
                            className='result'
                            type="text"
                            id="expected-rate"
                            placeholder=""
                            value={averageProfit}
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div className='profit-calculator-img'>
                <iframe src="https://lottie.host/embed/d1e73edf-fbca-45fc-9fad-eb41acdb45a0/kc9rwPMazt.json"
                title='calculator'
                ></iframe>
            </div>
        </div>
    );
};

export default ProfitCalculator;

