import './tariffCard.scss'
import hotDeal from './../../resources/images/icons/hot-deal.png'

const TariffCard = ({title, price, term, termText, features, buttonLink, buttonText, sale}) => {

    return (
        <article className="tariff-card" data-aos="zoom-in" data-aos-duration="1000">
            {sale && (
            <div className="tariff-card-sale">
                <img className="tariff-card-sale-image" src={ hotDeal } alt="hotDeal-icon"/>
            </div>
            )}
            <div className="tariff-card-header">
                <h3 className="tariff-card-title title-3">{ title }</h3>
                <div className="tariff-card-price">{ price }</div>
                <div className="tariff-card-term">{ termText }<span>{ term }</span></div>
            </div>
            <ul className="tariff-card-list">
            {features.map((feature, index) => (
                <li key={index} className="tariff-card-item _icon-icon-done">{feature}</li>
            ))}
            </ul>
            <a className="button tariff-card-button" href={ buttonLink } target="_blank" rel="noreferrer">{buttonText}</a>
        </article>
    );
  };
  
export default TariffCard