import './titleSection.scss'

const TitleSection = ({ title, subtitle }) => {
    return(
        <div className="section-header" data-aos="fade-down" data-aos-duration="1500">
            <h2 className="section-title" dangerouslySetInnerHTML={{ __html: title }}></h2>
            <p className="section-subtitle">{subtitle}</p>
        </div>
    )
}

export default TitleSection