import { createContext } from "react";

const AuthContext = createContext({
    isAuthenticated: false,
    setIsAuthenticated: () => {},
    email: null,
    setEmail: () => {},
    isLoading: true,
    setIsLoading: () => {},
});

export default AuthContext;