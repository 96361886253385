import { NavLink } from 'react-router-dom'

import './accountNav.scss'

import account from './../../../resources/images/account/avatar.png'
import team from './../../../resources/images/account/team.png'
import stats from './../../../resources/images/account/stats.png'
import deposit from './../../../resources/images/account/deposit.png'
import topUp from './../../../resources/images/account/top-up.png'
import withdraw from './../../../resources/images/account/withdraw.png'

const AccountNav = () => {
    const navItems = [
        {
            href: "/profile",
            src: account,
            title: "Кабинет"
        },
        {
            href: "/deposit",
            src: deposit,
            title: "Мой счёт"
        },
        {
            href: "/topup",
            src: topUp,
            title: "Пополнить баланс"
        },
        {
            href: "/withdrawal",
            src: withdraw,
            title: "Вывод прибыли"
        },
        {
            href: "/partners",
            src: team,
            title: "Мои партнёры"
        },
        {
            href: "/calendar",
            src: stats,
            title: "Калентарь сделок"
        },
    ]

    return(
        <ul className="account-nav">
            {
                navItems.map((item, index) => {
                    return(
                        <li key={index} className="account-nav-item">
                            <NavLink 
                                className="account-nav-link" 
                                to={ `.${item.href}` }>
                                <img src={ item.src } alt="nav-link" />
                                <h3 className="account-nav-title">{ item.title }</h3>
                            </NavLink>
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default AccountNav