import './preloader.scss';

const Preloader = () => {
    return (
        <div className="preloader">
            <div className="circle"></div>
            <div className="loading-text">Loading...</div>
        </div>
    );
};

export default Preloader;