import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyA8bLNvqLWw_rbT3TjykGTLl7ABUGOqh0s",
    authDomain: "imperiumbroker-75181.firebaseapp.com",
    projectId: "imperiumbroker-75181",
    storageBucket: "imperiumbroker-75181.appspot.com",
    messagingSenderId: "782246277489",
    appId: "1:782246277489:web:4246d23a930f8fa60f86d0"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };