import { useState } from 'react';
import ContactSocial from '../contactSocial/ContactSocial';
import sendMessageToTelegram from '../../services/telegramService';

import './contacts.scss';

const Contacts = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Функция для обработки отправки формы
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Проверка заполненности полей
        if (!name || !email || !message) {
            setError('Пожалуйста, заполните все поля.');
            return;
        }

        // Формируем сообщение для Telegram
        const telegramMessage = `<b>Обратная связь</b>\n` +
                                `<b>Имя:</b> ${name}\n` +
                                `<b>Email:</b> ${email}\n` +
                                `<b>Сообщение:</b> ${message}\n`;

        // Отправка сообщения в Telegram
        try {
            await sendMessageToTelegram(telegramMessage);
            setName('');
            setEmail('');
            setMessage('');
            setError('');
            setIsSubmitted(true);
            
            setTimeout(() => {
                setIsSubmitted(false); 
            }, 5000);
            
        } catch (error) {
            console.error('Ошибка отправки сообщения:', error);
            setError('Ошибка отправки сообщения. Попробуйте позже.');
        }
    };

    return (
        <section className="contact">
            <div className="container">
                <div className="contact-wrapper">
                    <div className="contact-body" data-aos="zoom-in-up" data-aos-duration="1000">
                        <div className="contact-body-header">
                            <h3 className="contact-title title-3">Обратная связь</h3>
                            <p className="section-subtitle contact-subtitle">
                                Если у&nbsp;вас остались вопросы, напишите нам, и&nbsp;наши менеджеры свяжутся с&nbsp;вами как можно быстрее.
                            </p>
                        </div>
                        {error && <p className="error-message">{error}</p>}
                        <form className="contact-form" onSubmit={handleSubmit}>
                            <input
                                className="contact-form-input"
                                type="text"
                                placeholder="Ваше имя"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <input
                                className="contact-form-input"
                                type="email"
                                placeholder="Ваш email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <textarea
                                className="contact-form-textarea"
                                placeholder="Сообщение"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            ></textarea>
                            <button
                                className={`contact-form-button button ${isSubmitted ? 'submitted' : ''}`}
                                type="submit"
                                disabled={isSubmitted}
                            >
                                {isSubmitted ? 'Письмо отправлено' : 'Отправить'}
                            </button>
                        </form>
                    </div>

                    <ContactSocial />
                </div>
            </div>
        </section>
    );
};

export default Contacts;

