import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { NavLink } from 'react-router-dom'; // Импортируем NavLink
import 'swiper/css';
import 'swiper/css/effect-flip';

import './hero.scss';
import heroBg from './../../resources/images/hero/bg.svg';
import image06 from './../../resources/images/hero/image06.png';
import imageCrypto from './../../resources/images/hero/header-title-icon.png'
import exchangeImg from './../../resources/images/hero/exchangeHero.png'

const Hero = () => {

    return(
        <section className="hero">
            <div className="hero-bg">
                <img src={heroBg} alt="hero-bg"/>
            </div>
            <div className="container">
                <div className="hero-wrapper">
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                            delay: 10000,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                    >
                        <SwiperSlide>
                            <div className="hero-descr"> 
                                <h1 className="hero-title">
                                    <span data-aos="zoom-out-down" data-aos-duration="500" data-aos-delay="600">Зарабатывайте</span>
                                    <span data-aos="zoom-out-down" data-aos-duration="500" data-aos-delay="900">криптовалюту с помощью</span>
                                    <span data-aos="zoom-in-up" data-aos-duration="500" data-aos-delay="1300">
                                        Стейкинга
                                        <img className="hero-title-img" src={imageCrypto} alt="imageCrypto" />
                                    </span>
                                </h1>
                                <p className="hero-text text">Простой, безопасный и ликвидный стейкинг с лучшей годовой процентной ставкой от ImperiumBroker.</p>
                                <a className="button hero-button" href="https://t.me/ImperiumBroker_manager" target="_blank" rel="noreferrer" data-aos="zoom-in-up" data-aos-duration="500" data-aos-delay="800">Связаться с нами</a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="hero-descr"> 
                                <h1 className="hero-title">
                                    Криптовалютный обменник с лучшими&nbsp;
                                    <span className='hero-title-exchange'>
                                    курсами
                                    <img src={exchangeImg} alt="exchangeImg" />
                                    </span>
                                </h1>
                                <p className="hero-text text">Удобный и безопасный обмен криптовалют с минимальными комиссиями. Получите лучшие условия обмена на платформе ImpEx за несколько кликов.</p>
                                <NavLink to="/exchange" className="button hero-button">Обменять криптовалюту</NavLink>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="hero-image" data-aos="zoom-in" data-aos-duration="1500">
                        <img src={image06} alt="hero"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;