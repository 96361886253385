import './faqList.scss'
import QuestionImg from './../../resources/images/icons/question-and-answer.png'

const textItem = [
    {
        img: QuestionImg,
        title: 'Как сделать стейкинг?',
        text: `После того как ваш аккаунт зарегистрирован, вы можете перейти к выбору инвестиционного плана 
        стейкинга. Сравните итоговый процент профита, определитесь с суммой и платежной системой. 
        Также вы можете использовать реферальные вознаграждения для стейкинга.`
    },
    {
        img: QuestionImg,
        title: 'Как долго средства начисляются на баланс?',
        text: 'Средства поступают на ваш баланс моментально и сразу же отправляются в работу. Если перевод средств сделан через криптовалюту, они зачисляются после необходимого количества подтверждений в сети. Если ваши деньги не поступили на баланс в течение 24 часов, напишите в поддержку.'
    },
    {
        img: QuestionImg,
        title: 'Есть ли у компании комиссии на ввод и вывод денежных средств?',
        text: 'ImperiumBroker взымает комиссию на вывод Tether (ERC20) - 5 USDT, USD Coin (ERC20) - 6 USDC, Arbitrum (ERC20) - 4.8 ARB, Bitcoin - 0.0001 BTC, Ethereum (ERC20) - 0.0021 ETH, со всех остальных платежных систем комиссия не взымается.'
    },
    {
        img: QuestionImg,
        title: 'Можно ли изменить данные кабинета: пароль, логин, реквизиты, электронную почту?',
        text: 'Да, ваш e-mail или пароль вы можете изменить по запросу в техподдержке. Номер кошелька, с которого был сделан стейкинг и на который приходят выплаты указывается каждый раз при выводе средств. Создайте запрос на смену данных через любой из контактов, указанных на сайте.'
    },
    {
        img: QuestionImg,
        title: 'Забыл пароль от аккаунта. Что делать?',
        text: 'Если вы забыли пароль от своего аккаунта, обратитесь в нашу службу поддержки. Мы поможем вам восстановить доступ к вашему аккаунту. Обратите внимание, что мы не храним пароли на наших серверах для обеспечения максимальной безопасности ваших данных. Поэтому процедура восстановления пароля требует обращения к нам. Наши специалисты предоставят инструкции по созданию нового пароля и восстановлению доступа.'
    },
    {
        img: QuestionImg,
        title: 'У меня остались вопросы. Как связаться с поддержкой?',
        text: 'Для получения консультации перейдите в раздел "Контакты" на сайте компании. Напишите свой вопрос в форму обратной связи. Или напишите нашей службе техподдержки в Telegram.'
    },
    {
        img: QuestionImg,
        title: 'Что нужно, чтобы стать инвестором компании ImperiumBroker?',
        text: 'Зарегистрируйтесь в личном кабинете. Укажите ваш e-mail адрес (который будет вашим логином), пароль. Пожалуйста, заполняйте только достоверные данные. После успешной регистрации зайдите в личный кабинет и откройте счёт в соответствующем разделе.'
    },
    {
        img: QuestionImg,
        title: 'С какими платежными системами работает платформа?',
        text: 'Сумма стейкинга от 50$ доступна в следующих криптовалютах: BTC, Tether (TRC20), Tron, Tether (ERC20), ETH - на сайте в личном кабинете. BCH, Ripple, LTC, Dash, Doge, Tron, BNB(BEP20) и другие - по запросу. А так же в платежных системах, как Perfect Money, Payeer, YooMoney. Весь список можете увидеть в личном кабинете сайта. Если у вас нет ни одного из этих кошельков, вы можете создать их.'
    },
    {
        img: QuestionImg,
        title: 'Как происходит вывод прибыли?',
        text: 'В вашем личном кабинете создайте запрос на вывод средств. Укажите платежную систему и сумму, которую хотите вывести. Вывод происходит на любую ЭПС, а не только ту, с которой был сделан стейкинг. Партнерские бонусы доступны на балансе сразу. Вывод средств производится вручную и может занимать до 24 часов.'
    },
    {
        img: QuestionImg,
        title: 'Минимально допустимые суммы для вывода?',
        text: 'Для долларовых стейкингов минимально допустимая к выводу сумма составляет: Atom - $3, Bitcoin - $10, Bitcoincash - $15, BUSD - $3, Cardano - $3, c98 - $3, BNB - $7, Dash - $5, Doge - $7, ERC20 - $15, Ethereum - $15, Litecoin - $3, Matic - $3, Ripple - $5, Tron - $3, TRC20 - $3, USDT BEP20 - $3, USDT Polygon - $3, Arbitrum ERC20 - $15, DAI - $3, Монеты в сети BEP20 - $3, Nix, NOT - $2, TON - $3, Perfect Money/Payeer - 1$, Yoomoney - 100 руб. '
    },
    {
        img: QuestionImg,
        title: 'Какая у компании партнерская программа?',
        text: '7%, если Ваш партнёр откроет счёт по Пассивному доходу. С каждого партнёра можно получить реферальные начисления один раз. Для того, что б зарегистрироваться на нашей платформе, как рефовод, обратитесь в службу заботы о клиентах и уточните детальную информацию.'
    },
    {
        img: QuestionImg,
        title: 'Есть ли реферальный бонус при стейкинге с баланса?',
        text: 'За реинвест с баланса вы получите дополнительно 1% к сумме вашего стейкинга.'
    },
    {
        img: QuestionImg,
        title: 'Могу ли я зарегистрировать несколько аккаунтов?',
        text: 'Пользователь может иметь только один аккаунт. Множественные аккаунты запрещены. Нарушение этого правила может привести к одностороннему разрыву сотрудничества и заморозке счетов инвестора.'
    },
    {
        img: QuestionImg,
        title: 'Доступен ли мне мой аккаунт в любой момент?',
        text: 'Да, личный кабинет инвестора доступен круглосуточно.'
    },
    {
        img: QuestionImg,
        title: 'Правила фиксации курса для обмена криптовалют',
        text: 'Правила фиксации курса: Вне зависимости от того, в какую сторону будет изменен курс, его окончательная фиксация будет проведена на момент фактического зачисления средств на наш кошелек.'
    },
    {
        img: QuestionImg,
        title: 'Сколько подтверждений необходимо, что б заявка на обмен считалась оплаченной?',
        text: 'Подтверждение транзакции нужно для того, чтобы предотвратить повторный расход одних и тех же денежных средств. Как только отправитель переводит средства, транзакция попадает в сеть для исполнения и включения в блок.'
    },
    {
        img: QuestionImg,
        title: 'Оплатил заявку на обмен, когда она будет выполнена?',
        text: 'Регламент проведения операций по автоматическим направлениям: 1-15 минут. Среднее время: 5 минут.'
    },
    {
        img: QuestionImg,
        title: 'Прошло 30 минут, а заявка на обмен не выполнена',
        text: 'Если заявка после вашей оплаты имеет статус "Принята, ожидает оплаты клиентом" на протяжении 30 минут и более, напишите в поддержку, мы обязательно проверим в чём проблема.'
    },
    {
        img: QuestionImg,
        title: 'Прошло 30 минут, а заявка на обмен не выполнена',
        text: 'Если заявка после вашей оплаты имеет статус "Принята, ожидает оплаты клиентом" на протяжении 30 минут и более, напишите в поддержку, мы обязательно проверим в чём проблема.'
    },
]

const FaqList = () => {

    return (
        <ul className='faq-list'>
            {textItem.map((item, index) => (
                <li key={index} className='faq-list-item'>
                    <img className='faq-list-icon' src={item.img} alt="question" />
                    <h3 className='faq-list-title'>{item.title}</h3>
                    <p className='faq-list-text text'>{item.text}</p>
                </li>
            ))}
        </ul>
    )

}

export default FaqList