import React, { useEffect, useState } from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import { QRCodeCanvas } from 'qrcode.react'; 
import './exchangePayment.scss'

import copyImg from './../../../resources/images/icons/copy-color.png'

const ExchangePayment = ({ fromCurrency, toCurrency, fromAmount, toAmount, exchangeRate, userData, onCancelClick }) => {
    const [requestId, setRequestId] = useState('');
    const [timeLeft, setTimeLeft] = useState(1800);
    const [isCopied, setIsCopied] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [progress, setProgress] = useState(0);// Новое состояние для прогресса

    // Генерация случайного ID
    useEffect(() => {
        const generateRequestId = () => {
            const randomId = Math.floor(10000 + Math.random() * 90000); // Генерация числа от 10000 до 99999
            setRequestId(randomId.toString());
        };
        generateRequestId();
    }, []);

    // Анимация прогресса: от 0 до 100% за 30 секунд
    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(prevProgress => {
                if (prevProgress >= 100) {
                    return 0;
                } else {
                    return prevProgress + 1;
                }
            });
        }, 300);

        return () => clearInterval(interval);
    }, []);

    // Таймер обратного отсчета
    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft(prevTime => {
                    if (prevTime === 1) {
                        setIsExpired(true);
                    }
                    return prevTime - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [timeLeft]);

    // Форматирование времени (минуты:секунды)
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
    };

    // Функция для копирования текста в буфер обмена
    const copyToClipboard = (text) => {
    if (!text) {
        console.log('Нечего копировать, адрес отсутствует');
        return;
    }

    navigator.clipboard.writeText(text)
        .then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 3000);
        })
        .catch((err) => {
            console.error('Не удалось скопировать текст:', err);
        });
    };
    
    return (
        <section className='swap'>
            <div className='swap-wrapper'>
                <div className='swap-top'>
                    <h2 className='swap-top-title-number title-3'>Заявка:<span>ID {requestId}</span></h2>
                    <h2 className='swap-top-title-status'><span>Статус заявки:</span><span className='text'>Принята, ожидает оплаты клиентом</span></h2>
                    <div className='swap-top-progress'>
                        <ProgressBar
                            completed={progress}
                            bgColor="linear-gradient(90deg, rgba(0,36,255,1) 0%, rgba(0,255,121,1) 100%)"
                            height="10px"
                            width="100%"
                            baseBgColor="#e0e0e0"
                            transitionDuration="0.3s"
                            transitionTimingFunction="linear"
                            labelColor="transparent"
                            isLabelVisible={false}
                            animateOnRender={true}
                        />
                    </div>
                    <div className='swap-top-time'>
                        <div className='swap-top-time-icon'>
                            <iframe 
                                src="https://lottie.host/embed/d352b886-8e60-48f1-8a66-8fa000311723/mD6DLPfHFL.json"
                                allowFullScreen
                                title="time"
                                >
                            </iframe>
                        </div>
                        <div>
                            {isExpired 
                                ? 'Заявка удалена. Создайте новую заявку.' 
                                : `Оплатите заявку в течении ${formatTime(timeLeft)}`}
                        </div>
                    </div>
                </div>
                <div className='swap-descr'>
                    <p className='text'>Курс по заявке является предварительным и будет зафиксирован на следующих условиях:<br/>
                    - Если на момент 3 подтверждений в сети курс с момента создания заявки изменится менее чем на 0.1% по бирже Binance, или останется без изменений, то будет зафиксирован курс в заявке.<br/>
                    - В случае изменения курса с момента создания заявки более чем на 0.3% по бирже Binance, тогда будет зафиксирован актуальный курс на момент получения 3 подтверждений сети по бирже Binance.
                    </p>
                    <h3 className='swap-descr-title title-3'>Как оплатить</h3>
                    <ul className='swap-descr-list text'>
                        <li className='swap-descr-item'>
                            1. Проверьте правильность всех данных в заявке.
                        </li>
                        <li className='swap-descr-item'>
                            2. Скопируйте наш адрес для отправки средств (кнопка "копировать") или воспользуйтесь QR кодом.
                        </li>
                        <li className='swap-descr-item'>
                            3. Произведите оплату вашей заявки на указанный адрес сгенерированного кошелька в течении 30 минут. После зачисления средств страница автоматически обновится и вы увидите статус "Заявка выполнена", а средства поступят на ваш счёт.
                        </li>
                    </ul>
                </div>
                <div className='swap-transfer'>
                <div className='swap-transfer-rate'>
                        <p>Курс:</p>
                        {/* Выводим курс выбранной валютной пары */}
                        <div>
                            {exchangeRate 
                                ? `1 ${fromCurrency?.symbol.toUpperCase()} = ${exchangeRate.toFixed(5)} ${toCurrency?.symbol.toUpperCase()}`
                                : 'Курс не доступен'}
                        </div>
                    </div>
                    <div className='swap-transfer-body'>
                        <div className='swap-transfer-blocks'>
                            <div className='swap-transfer-block'>
                                <h3>Отдаёте</h3>
                                <p className='text'>Сумма:</p>
                                <div className='swap-transfer-block-sum'>
                                    {fromAmount} {fromCurrency?.name} {fromCurrency?.network && ` ${fromCurrency.network}`}
                                    <img src={fromCurrency?.image} alt={fromCurrency?.name} />
                                </div>
                                <p className='text'>На счёт:</p>
                                <div className='swap-transfer-block-currency'>
                                    <div className='swap-transfer-block-adress'>{fromCurrency?.address ? fromCurrency.address : 'Адрес кошелька не найден'}</div>
                                </div>
                            </div>

                            <div className='swap-transfer-block'>
                                <h3>Получаете</h3>
                                <p className='text'>Сумма:</p>
                                <div className='swap-transfer-block-sum'>
                                    {toAmount} {toCurrency?.name} {toCurrency?.network && ` ${toCurrency.network}`}
                                    <img src={toCurrency?.image} alt={toCurrency?.name} />
                                </div>
                                <p className='text'>На счёт:</p>
                                <div className='swap-transfer-block-currency'>
                                    <div className='swap-transfer-block-adress'>{userData.accountAddress ? userData.accountAddress : 'Адрес кошелька не найден'}</div>
                                </div>
                            </div>
                            <div className='swap-transfer-block'>
                                <h3>Личные данные</h3>
                                <ul className='swap-transfer-block-list text'>
                                    <li>E-mail: {userData.email}</li>
                                    <li>Telegram: {userData.telegramUsername}</li>
                                </ul>
                            </div>
                        </div>
                        <div className='swap-transfer-details'>
                            <div className='swap-transfer-details-top'>
                                <h3>Адрес {fromCurrency?.name}</h3>
                                <div className='swap-transfer-details-adress'>{fromCurrency?.address ? fromCurrency.address : 'Адрес кошелька не найден'}</div>
                                <button 
                                    className={`swap-transfer-details-copy ${isCopied ? 'copied' : ''}`}
                                    onClick={() => copyToClipboard(fromCurrency?.address)}
                                >
                                    <span>{isCopied ? 'Скопировано' : 'Копировать'}</span>
                                    <img src={ copyImg } alt="copy" />
                                </button>
                            </div>
                            <div className='swap-transfer-details-bottom'>
                                <h3>QR код</h3>
                                {fromCurrency?.address && (
                                    <div className="qr-code">
                                        <QRCodeCanvas value={fromCurrency.address} size={180} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='swap-transfer-cancel'>
                        <button 
                        className='button swap-transfer-cancel-button'
                        onClick={onCancelClick}
                        >
                        Отменить заявку
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ExchangePayment