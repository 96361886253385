import { useLocation } from "react-router-dom";
import { useState, useEffect, useRef, useContext } from "react"
import { useNavigate } from "react-router-dom"
import AuthContext from "../context/AuthContext"
import { auth } from "../services/firebaseConfig"
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth"

import { db } from "../services/firebaseConfig";
import { collection, addDoc, Timestamp } from "firebase/firestore"

import image from "./../resources/images/registration/registration.png"
import imageGoogle from "./../resources/images/registration/google.png"
import thumbUp from "./../resources/images/icons/thumb-up.png"
import Crying from './../resources/images/icons/crying.png'

const Registration = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { setIsAuthenticated, setEmail } = useContext(AuthContext)
    const [isLoginMode, setIsLoginMode] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [error, setError] = useState(null)
    const [passwordMismatch, setPasswordMismatch] = useState(false) 
    const [referrerId, setReferrerId] = useState(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setReferrerId(searchParams.get("referrerId"));
    }, [location.search]);

    const handleToggleMode = () => {
    setIsLoginMode((prev) => !prev)
    };

    const formRef = useRef()

    // Функция для сброса полей формы
    const resetForm = () => {
        formRef.current.reset()
    };

    // Регистрация пользователя с помощью имейла и пароля
    const handleEmailPasswordRegistration = async (email, password) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password)
            const user = userCredential.user
            await createUserInFirestore(user)
            setIsAuthenticated(true)
            setEmail(user.email)
            resetForm();
            setIsSuccess(true)
            setTimeout(() => {
                navigate("/account")
            }, 2000)
            } catch (error) {
                setError(error.message)
            }
        };

    // Регистрация пользователя с помощью Google
    const handleGoogleRegistration = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const userCredential = await signInWithPopup(auth, provider)
            const user = userCredential.user
            await createUserInFirestore(user); 
            setEmail(user.email)
            setIsAuthenticated(true)
            setIsSuccess(true)
            navigate("/account")

        } catch (error) {
            console.error("Error during Google registration:", error)
        }
    };

    // Вход пользователя с помощью имейла и пароля
    const handleEmailPasswordLogin = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password)
            const user = userCredential.user
            setEmail(user.email)
            setIsAuthenticated(true)
            resetForm()
            setIsSuccess(true)
            setTimeout(() => {
                navigate("/account")
              }, 2000)
        } catch (error) {
            setError(error.message)
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault()

        const formData = new FormData(event.target)
        const email = formData.get("email")
        const password = formData.get("password")
        const passwordConfirmation = formData.get("passwordConfirmation")

        // Проверка совпадения паролей
        if (!isLoginMode && password !== passwordConfirmation) {
            setPasswordMismatch(true)
            return;
        } else {
            setPasswordMismatch(false)
        }

        if (isLoginMode) {
            handleEmailPasswordLogin(email, password)
        } else {
            handleEmailPasswordRegistration(email, password)
        }
    };

    // добавление данных пользователя в Firestore
    const createUserInFirestore = async (user) => {
        try {
            const referralLink = `https://www.imperiumbroker.pro/register?referrerId=${user.uid}`;
            const userData = {
                email: user.email,
                registrationDate: Timestamp.fromDate(new Date()),
                accountBalance: 0,
                totalEarn: 0,
                dailyProfit: 0,
                dailyPercentage: 0,
                referrerId: referrerId || null,
                referralLink,
            };
    
            await addDoc(collection(db, "users"), userData);
        } catch (error) {
            console.error("Error adding user to Firestore: ", error);
        }
    };

  return (
    <section className="registration">
        <div className="container">
            <div className="registration-wrapper">
                <img className="registration-image" src={image} alt="registration" />
                <div className="registration-body">
                    <div className="registration-body-header">
                        <h2 className="registration-body-title">
                            {isLoginMode ? "Вход" : "Регистрация"}
                        </h2>
                        <div className="registration-body-question">
                            <div className="registration-body-text">
                            {isLoginMode ? "Еще нет аккаунта?" : "Уже зарегистрированы?"}
                            </div>
                            <button
                                className="registration-body-text registration-body-text-enter"
                                onClick={handleToggleMode}
                            >
                            {isLoginMode ? "Зарегистрироваться" : "Войти в аккаунт"}
                            </button>
                        </div>
                    </div>
                    <form
                        ref={formRef} 
                        onSubmit={handleSubmit}
                        className="registration-form" 
                        action="#">
                        <input
                            className="registration-form-input"
                            type="email"
                            name="email"
                            placeholder="Email"
                            required
                        />
                        <input
                            className="registration-form-input"
                            type="text"
                            name="password"
                            placeholder="Пароль (минимум 6 символов)"
                            required
                        />

                        {!isLoginMode && (
                            <input
                            className="registration-form-input"
                            type="text"
                            name="passwordConfirmation"
                            placeholder="Повторить пароль"
                            required
                            />
                        )}

                        {isSuccess ? (
                            <div className="registration-success">
                                <div>
                                    {isLoginMode ? "Вы вошли в аккаунт" : "Вы успешно зарегистрировались"}
                                </div>
                                <div className="registration-success-icon">
                                    <img src={thumbUp} alt="modal-icon"/>
                                </div>
                            </div>
                        ) : (
                            <div>
                                { passwordMismatch && (
                                <div className="registration-error">
                                    <img className="registration-error-image" src={Crying} alt="crying-icon"/>
                                    <span>Пароли не совпадают</span>
                                </div>
                                )}
                                { error && !passwordMismatch && (
                                <div className="registration-error">
                                    <img className="registration-error-image" src={Crying} alt="crying-icon"/>
                                    <span>Вы ввели неверный логин или пароль. Пожалуйста, попробуйте снова!</span>
                                </div>
                                )}
                                <button className="registration-form-button button" type="submit">
                                    {isLoginMode ? "Войти" : "Зарегистрироваться"}
                                </button>
                            </div>
                        )}

                        <button
                            className="registration-form-button registration-form-button-google button"
                            type="button"
                            onClick={handleGoogleRegistration}
                        >
                            {isLoginMode
                            ? "Продолжить в Google"
                            : "Продолжить в Google"}
                            <img
                            className="registration-form-button-image"
                            src={imageGoogle}
                            alt="google"
                            />
                        </button>
                        {isLoginMode && (
                            <a
                                className="registration-body-text registration-body-text-enter registration-body-text-reset"
                                href="https://t.me/ImperiumBroker_manager" 
                                target="_blank" 
                                rel="noreferrer"
                            >
                                Забыли пароль?
                            </a>
                        )}
                    </form>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Registration

