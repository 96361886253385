import { useState } from 'react';

import './paymentSystems.scss'

import bitcoin from './../../../resources/images/payments/bitcoin.png'
import ethereum from './../../../resources/images/payments/ethereum.png'
import tether from './../../../resources/images/payments/tether.png'
import tron from './../../../resources/images/payments/tron.png'
import perfect from './../../../resources/images/payments/perfect.svg'
import payeer from './../../../resources/images/payments/payeer.png'
import yoomoney from './../../../resources/images/payments/yoomoney.png'
import numberOne from './../../../resources/images/icons/number-one.png'
import number2 from './../../../resources/images/icons/number-2.png'


const PaymentSystems = ({ onAmountChange, amount, onPaymentSystemChange, onNextStep, isReadyForNextStep }) => {

    const [selectedPaymentSystem, setSelectedPaymentSystem] = useState(null);

    const handlePaymentSystemChange = (id) => {
        const selectedWallet = wallets.find(wallet => wallet.id === id);
        setSelectedPaymentSystem(selectedWallet);
        onPaymentSystemChange(selectedWallet);
    };

    const wallets = [
        {   
            id: 1,
            src: bitcoin,
            title: 'Bitcoin',
            address: 'bc1qs7lusgwnv4633qsxkep6uvh8q8hp9twtgnsqma'
        },
        {
            id: 2,
            src: ethereum,
            title: 'Ethereum',
            address: '0xfCA87C9D48D4bcA6F2F4077a98A7733e28A7C07e'
        },
        {
            id: 3,
            src: tether,
            title: 'Tether trc20',
            address: 'TWsNX8ghZhP27c5oyKQHF2BALEXRGGtiT7'
        },
        {
            id: 4,
            src: tether,
            title: 'Tether erc20',
            address: 'TWsNX8ghZhP27c5oyKQHF2BALEXRGGtiT7'
        },
        {
            id: 5,
            src: tron,
            title: 'Tron',
            address: 'TWsNX8ghZhP27c5oyKQHF2BALEXRGGtiT7'
        },
        {
            id: 6,
            src: perfect,
            title: 'Perfect money',
            address: 'U28098453'
        },
        {
            id: 7,
            src: payeer,
            title: 'Payeer',
            address: 'P1027892104'
        },
        {
            id: 8,
            src: yoomoney,
            title: 'Yoomoney',
            address: '2204120109433926'
        },
    ]

    return(
        <div className="payment-systems">
            <div className="topup-step">
                <div className="topup-step-header">
                    <img src={ numberOne } alt="step 1" />
                    <h5 className="text">Введите сумму пополнения:</h5>
                </div>
                    <input
                        value={ amount }
                        onChange={ onAmountChange } 
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9.]+/, '');
                        }}
                        className="payment-systems-amount" 
                        type="text" 
                    />
            </div>
            <div className="topup-step">
                <div className="topup-step-header">
                    <img src={ number2 } alt="step 2" />
                    <h5 className="payment-systems-title text">Выберите удобный для Вас способ отправки средств: криптовалюта или электронные кошельки</h5>
                </div>
                <ul className="payment-systems-list">
                    {
                        wallets.map((item) => {
                            return (
                                <li 
                                    className={`payment-systems-item ${selectedPaymentSystem && selectedPaymentSystem.id === item.id ? ' selected' : ''}`}
                                    key={ item.id } 
                                    style={{
                                        opacity:
                                        !selectedPaymentSystem || selectedPaymentSystem.id === item.id ? 1 : 0.3,
                                    }}
                                    >
                                    <input
                                        onChange={() => handlePaymentSystemChange(item.id)}
                                        checked={selectedPaymentSystem === item.id}
                                        value={ item.address }
                                        className="payment-systems-item-radio"
                                        type="radio"
                                        name="paymentSystem"
                                        />
                                    <img src={ item.src } alt="payment-icon"/>
                                    <h4 className="payment-systems-item-title">{ item.title }</h4>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="payment-systems-bottom">
                <button 
                    onClick={ onNextStep }
                    style={{ opacity: isReadyForNextStep ? 1 : 0.3 }}
                    disabled={ !isReadyForNextStep }
                    className="button payment-systems-button"
                >
                    Перейти к оплате
                </button>
            </div>
        </div>
    )
}

export default PaymentSystems