import axios from "axios";

const token = '6168088573:AAFLSycfNeLpBAqANU0H69fXwhcFe7ZRY4k'
const chat_id = '-895675197';
const uri_api = `https://api.telegram.org/bot${token}/sendMessage`

const sendMessageToTelegram = async (message) => {
    await axios.post(uri_api, {
        chat_id: chat_id,
        parse_mode: 'html',
        text: message
    });
};

export default sendMessageToTelegram