import './tradingSection.scss'
import BlockInfo from '../blockInfo/BlockInfo'
import BlockHow from '../blockHow/BlockHow'
import TitleSection from '../titleSection/TitleSection'
import ProfitDiagram from '../diagrams/profitDiagram/ProfitDiagram'

import image1 from './../../resources/images/trading/image1.png'
import image2 from './../../resources/images/trading/image2.png'

import howIcon1 from './../../resources/images/profit/how-icon1.svg'
import howIcon2 from './../../resources/images/profit/how-icon2.svg'
import howIcon3 from './../../resources/images/profit/how-icon3.svg'

const TradingSection = () => {

    const tradingBlockInfo = [
        {
            img: image1,
            title: 'Заключайте сделку с ImperiumBroker и получайте ежедневную прибыль!',
            text: `С ImperiumBroker вы получаете уникальную возможность приумножить свои средства через стейкинг. 
            Наш подход позволяет инвесторам ежедневно зарабатывать от 1.5% прибыли, просто храня свои криптомонеты. 
            Благодаря алгоритму Proof of Stake (PoS) ваши активы работают на вас, обеспечивая стабильный доход без лишних усилий. 
            Наша команда гарантирует безопасность и прозрачность процесса, предоставляя вам всю необходимую информацию о ваших инвестициях. 
            Начните свой путь к финансовой независимости уже сегодня — сделайте первый шаг к успеху с ImperiumBroker и ощутите преимущества пассивного заработка на практике!`,
            class: 'trading-item'
        },
        {
            img: image2,
            title: 'Процесс партнёрства с нами полностью автоматизирован!',
            text: `С ImperiumBroker вы получаете доступ к высокотехнологичной платформе, которая оптимизирует каждый этап вашего партнёрства. 
            Мы внедрили автоматизированные алгоритмы, позволяющие сократить время на регистрацию и управление вашим аккаунтом. 
            Наша система использует API для бесшовной интеграции с блокчейн-протоколами, что обеспечивает мгновенное начисление вознаграждений за стейкинг.
            Кроме того, мы применяем продвинутые аналитические инструменты для мониторинга рыночной активности и оптимизации стратегий заработка. 
            Ваша прибыль автоматически распределяется по алгоритму Proof of Stake (PoS), что гарантирует максимальную доходность и минимизирует риски. 
            Всё это позволяет вам сосредоточиться на достижении своих финансовых целей, пока мы заботимся о безопасном и эффективном управлении вашими активами.`,
        },
      ];

    const tradingHow = [
        {
          icon: howIcon1,
          title: 'Открытие депозита',
          text: `<a class='link-decor' href='#'>Откройте</a> Ваш депозит на любую сумму и мы приступим к выполнению наших обязанностей`
        },
        {
          icon: howIcon2,
          title: 'Подтверждение информации',
          text: 'С Вами свяжется наш менеджер в telegram для уточнения всех деталей'
        },
        {
          icon: howIcon3,
          title: 'Получение прибыли',
          text: 'Через 24 часа на Ваш баланс поступит прибыль'
        }
      ];

    return (
        <section className="trading" id="trading">
            <div className="container">
                <div className="profit-wrapper trading-wrapper">
                    <TitleSection
                        title='Стейкинг: что это, как работает и как заработать?'
                        subtitle='Под стекингом нужно понимать получение пассивного заработка за счет простого хранения криптомонет. В таком процессе используется алгоритм Proof of Stake (PoS) – доказательство доли владения. 
                        Важное преимущество стейкинга заключается в том, что он является полноценной заменой майнинга. Пользователи могут зарабатывать деньги на криптовалюте, не используя специализированного оборудования. Когда пользователи выбирают блокчейн с таким алгоритмом и хранят монеты, им начисляется вознаграждение. 
                        Это стимулирует участников системы вовлекаться в процесс стейкинга и обеспечивать тем самым работоспособность блокчейна.'
                    />

                    <BlockInfo 
                        items={tradingBlockInfo}
                    />

                    <BlockHow 
                        items={tradingHow}
                    />
                    <ProfitDiagram/>
                </div>
            </div>
        </section>
    )
}

export default TradingSection;