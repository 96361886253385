import partner1 from './../../resources/images/partners/partner-1.svg';
import partner2 from './../../resources/images/partners/partner-2.svg';
import partner3 from './../../resources/images/partners/partner-3.svg';
import partner4 from './../../resources/images/partners/partner-4.svg';
import partner5 from './../../resources/images/partners/partner-5.svg';
import partner6 from './../../resources/images/partners/partner-6.svg';
import partner7 from './../../resources/images/partners/partner-7.svg';
import partner8 from './../../resources/images/partners/partner-8.svg';
import partner9 from './../../resources/images/partners/partner-9.svg';
import partner10 from './../../resources/images/partners/partner-10.svg';
import './partners.scss'

const partnersList = [
    { src: partner1, alt: 'partner1' },
    { src: partner2, alt: 'partner2' },
    { src: partner3, alt: 'partner3' },
    { src: partner4, alt: 'partner4' },
    { src: partner5, alt: 'partner5' },
    { src: partner6, alt: 'partner6' },
    { src: partner7, alt: 'partner7' },
    { src: partner8, alt: 'partner8' },
    { src: partner9, alt: 'partner9' },
    { src: partner10, alt: 'partner10' },
];

const Partners = () => {
    return (
        <div className='partners-company'>
            <div className='container'>
                <div className='partners-company-wrapper'>
                    <ul className='partners-company-list'>
                        {partnersList.map((partner, index) => (
                            <li className='partners-company-item' key={index}>
                                <img src={partner.src} alt={partner.alt} />
                            </li>
                        ))}
                    </ul>
                    <div className='partners-company-title'>
                        <p className='partners-company-text'>Наши партнёры</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partners;
