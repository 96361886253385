import React, { useEffect, useState, useCallback, useRef } from 'react'
import TitleSection from '../components/titleSection/TitleSection'
import ExchangeSetup from '../components/ExchangeOrder/ExchangeSetup/ExchangeSetup'
import ExchangePayment from '../components/ExchangeOrder/ExchangePayment/ExchangePayment'
import BlockHow from '../components/blockHow/BlockHow'
import Offer from '../components/offer/Offer'
import BlockInfo from '../components/blockInfo/BlockInfo'
import LastTradesChart from '../components/diagrams/lastTradesChart/LastTradesChart'
import './../styles/components/exchange.scss'

import arrowDownImg from './../resources/images/icons/down-chevron.png'
import arrowUpImg from './../resources/images/icons/up-chevron.png'
import rateImg from './../resources/images/icons/rate.png'
import transactionImg from './../resources/images/icons/transaction.png'
import safetyImg from './../resources/images/icons/safety.png'
import cardImg from './../resources/images/icons/by-crypto-small.png'
import fastImg from './../resources/images/icons/limitless-exchange-small.png'
import brilliantImg from './../resources/images/icons/pro-small.png'
import successImg from './../resources/images/icons/98-mobile.svg'
import millionImg from './../resources/images/icons/1m-mobile.svg'
import minutesImg from './../resources/images/icons/5min-mobile.svg'
import howIcon1 from './../resources/images/profit/how-icon1.svg'
import howIcon2 from './../resources/images/profit/how-icon2.svg'
import howIcon3 from './../resources/images/profit/how-icon3.svg'
import markImg from './../resources/images/icons/mark-list.png'

import coinsData from './../json/coinsData.json';

const exchangeHow = [
    {
      icon: howIcon1,
      title: 'Введите детали обмена',
      text: `Выберите обменную пару из списка. Вы точно найдёте ту, которая вам нужна`
    },
    {
      icon: howIcon2,
      title: 'Внесите депозит',
      text: 'Проверьте введенные данные, получите примерное время обмена, после чего отправьте средства на наш одноразовые депозитный кошелек'
    },
    {
      icon: howIcon3,
      title: 'Получите ваши средства',
      text: 'Наслаждайтесь магией ImpEx. Мы автоматически подберем для вас лучшие условия, обменяем ваши средства, и отправим их на указанный вами кошелек всего за несколько минут.'
    }
];

const offerItems = [
    { img: rateImg, title: 'Лучший обменный курс', text: 'Мы гарантируем лучшие курсы для наших клиентов' },
    { img: transactionImg, title: 'Широкий выбор активов', text: 'Поддержка более 100 криптовалют для обмена, покупки и продажи' },
    { img: safetyImg, title: 'С заботой о вас', text: 'Мы уважаем вашу приватность и не контролируем ваши средства' }
];

const aboutExchangeBlock = [
    {
        img: cardImg,
        title: 'Купить криптовалюту в telegram-боте за фиатные деньги',
        text: `
            <div class="exchange-bot-descr">
                На ImpEx вы можете купить любую монету за одну из поддерживаемых фиатных валют с помощью карты Visa, MasterCard, UnionPay, YooMoney. Минимальная сумма для обмена всего $2, а максимальной нет вовсе – вы можете обменять столько криптовалют, сколько захотите.
            </div>
        `,
        link: `<a class="button exchange-bot-button" href="https://t.me/impexchangebot" target="_blank" rel="noreferrer">Купить коин за фиат</a>`,
    },
    {
        img: brilliantImg,
        title: 'Ваш идеальный помощник в мире криптовалют: telegram-бот ImpEx',
        text: `
            Бот ImpEx предлагает пользователям множество преимуществ, которые делают процесс торговли криптовалютами более удобным и эффективным. Если вам неудобно пользоваться сайтом или же вы хотите зарабатывать на реферальной программе, то вот некоторые из ключевых аспектов, которые выделяют наш сервис:
            <ul class="exchange-bot-list">
                <li class="exchange-bot-item"><img src="${markImg}" alt="иконка" class="list-image" /> Удобство использования на телефоне в мессенджере</li>
                <li class="exchange-bot-item"><img src="${markImg}" alt="иконка" class="list-image" /> Покупка/продажа криптовалюты за фиатные деньги</li>
                <li class="exchange-bot-item"><img src="${markImg}" alt="иконка" class="list-image" /> Реферальная программа: приглашай знакомых и получай от 1% до 2.5% с их транзакций</li>
                <li class="exchange-bot-item"><img src="${markImg}" alt="иконка" class="list-image" /> Быстрые транзакции: мгновенные обмены без задержек</li>
                <li class="exchange-bot-item"><img src="${markImg}" alt="иконка" class="list-image" /> Безопасность: защита ваших данных и средств с использованием современных технологий шифрования</li>
                <li class="exchange-bot-item"><img src="${markImg}" alt="иконка" class="list-image" /> Доступ к аналитическим инструментам: получение актуальной информации о рынке для принятия обоснованных решений</li>
            </ul>
        `,
    },
];

const fastExchangeBlock = [
    {
        img: fastImg,
        title: 'Быстрый обмен криптовалют',
        text: `
            Среднее время на обмен составляет всего 5 минут, а весь процесс можно отслеживать в режиме реального времени прямо на странице обмена или в telegram-боте. Ваши транзакции выполняются с высокой скоростью и надежностью, что обеспечивает минимальные задержки и максимальную безопасность при каждой операции.
            <ul class='exchange-fast-list'>
                <li class='exchange-fast-item'>
                    <img src="${successImg}" alt="success" />
                    <p class='exchange-fast-item-text'>Показатель успешности</p>
                </li>
                <li class='exchange-fast-item'>
                    <img src="${millionImg}" alt="million" />
                    <p class='exchange-fast-item-text'>Довольных клиентов</p>
                </li>
                <li class='exchange-fast-item'>
                    <img src="${minutesImg}" alt="minutes" />
                    <p class='exchange-fast-item-text'>Среднее время обмена</p>
                </li>
            </ul>
        `,
    },
];

const Exchange = () => {

    const [coins, setCoins] = useState([]);
    const [exchangeRate, setExchangeRate] = useState(null);
    const [fromCurrency, setFromCurrency] = useState(null);
    const [toCurrency, setToCurrency] = useState(null);
    const [isFromSelectOpen, setIsFromSelectOpen] = useState(false);
    const [isToSelectOpen, setIsToSelectOpen] = useState(false);
    const [fromAmount, setFromAmount] = useState('');
    const [toAmount, setToAmount] = useState('');
    const [isSetupActive, setIsSetupActive] = useState(false);
    const [isFromAmountValid, setIsFromAmountValid] = useState(true);
    const [isPaymentActive, setIsPaymentActive] = useState(false);
    const fromInputRef = useRef(null);
    const [userData, setUserData] = useState({
        accountAddress: '',
        email: '',
        telegramUsername: '',
    });    

    // // Получаем список криптовалют и добавляем данные из JSON файла
    useEffect(() => {
        fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd')
            .then(response => response.json())
            .then(data => {
               
                // Объединяем данные из API и из JSON файла
                const updatedCoins = data.map(coin => {
                    const coinData = coinsData.find(c => c.id === coin.id) || {};
                    return {
                        ...coin,
                        network: coinData.network || '',
                        address: coinData.address || ''
                    };
                });

                setCoins(updatedCoins);
                setFromCurrency(updatedCoins[0]);
                setToCurrency(updatedCoins[1]);
            })
            .catch(error => console.error('Error fetching coins:', error));
    }, []);

    // Получаем курс
    const fetchExchangeRate = useCallback((fromCurrencyId, toCurrencyId) => {
        fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${fromCurrencyId},${toCurrencyId}&vs_currencies=usd`)
            .then(response => response.json())
            .then(data => {
                const fromRate = data[fromCurrencyId].usd;
                const toRate = data[toCurrencyId].usd;
                const rate = (fromRate / toRate) * 1.07;
                setExchangeRate(rate);
            })
            .catch(error => console.error('Error fetching exchange rate:', error));
    }, []);

    // Автоматически обновляем курс при выборе валют
    useEffect(() => {
        if (fromCurrency && toCurrency) {
            fetchExchangeRate(fromCurrency.id, toCurrency.id);
        }
    }, [fromCurrency, toCurrency, fetchExchangeRate]);

    // Пересчет суммы в поле "Получаете"
    useEffect(() => {
        if (exchangeRate && fromAmount) {
            const amount = parseFloat(fromAmount);
            if (!isNaN(amount) && amount >= 0) {
                const newToAmount = (amount * exchangeRate).toFixed(5);
                setToAmount(newToAmount);
            } else {
                setToAmount('');
            }
        } else {
            setToAmount('');
        }
    }, [fromAmount, exchangeRate]);

    // Функция для обработки ввода в первое поле (Отдаёте)
    const handleInputChange = useCallback((event) => {
        const value = event.target.value;
        if (/^\d*\.?\d*$/.test(value) || value === '') {
            setFromAmount(value);
            setIsFromAmountValid(true); // Сбрасываем состояние валидности при изменении
        }
    }, []);

    // Функция для обработки клика на кнопку "Обмен"
    const handleExchangeClick = () => {
        if (!fromAmount) {
            setIsFromAmountValid(false);
            fromInputRef.current.focus();
        } else {
            setIsSetupActive(true); // Переход на компонент ExchangeSetup только если поле заполнено
        }
    };

    // Функция для возврата на форму обмена
    const handleBackClick = () => {
        window.scrollTo(0, 0);
        setFromAmount('');
        setToAmount('');
        setFromCurrency(coins[0]);
        setToCurrency(coins[1]);
        setIsFromAmountValid(true);
        setIsSetupActive(false);
    };

    const handleCancelClick = () => {
        window.scrollTo(0, 0);
        setFromAmount('');
        setToAmount('');
        setFromCurrency(coins[0]);
        setToCurrency(coins[1]);
        setIsFromAmountValid(true);
        setIsSetupActive(false);
        setIsPaymentActive(false);
    };
    
    return (
        <section className="exchange">
            <div className='container'>
                {isPaymentActive ? (
                <ExchangePayment 
                    fromCurrency={fromCurrency} 
                    toCurrency={toCurrency} 
                    fromAmount={fromAmount} 
                    toAmount={toAmount} 
                    exchangeRate={exchangeRate}
                    userData={userData}
                    onCancelClick={handleCancelClick}
                />
                 ) : isSetupActive ? (
                    <ExchangeSetup
                        fromCurrency={fromCurrency}
                        toCurrency={toCurrency}
                        fromAmount={fromAmount}
                        toAmount={toAmount}
                        onBackClick={handleBackClick}
                        setIsPaymentActive={setIsPaymentActive}
                        setUserData={setUserData}
                    />
                ) : (
                    <div className='exchange-main'>
                        <TitleSection
                        title='Обмен криптовалют'
                        subtitle="Легкий и надежный обмен криптовалюты — используйте наш сайт или telegram-бот!"
                        />
                        <iframe className='exchange-main-iframe' src="https://lottie.host/embed/5f0ebb92-ac5e-4278-919c-52ed9e8fa154/RkJKmCidHk.json" title="Background Frame"></iframe>

                        {/* Основная форма обмена */}
                        <div className='exchange-inner'>
                            <form className="exchange-form">
                                {/* Блок 1: Отдаёте */}
                                <div className="exchange-form-block">
                                    <h3>Отдаёте</h3>
                                    <div className="exchange-form-row">
                                        <div className="exchange-form-select exchange-form-select1">
                                            <div
                                                className="exchange-form-select-header"
                                                onClick={() => setIsFromSelectOpen(!isFromSelectOpen)}
                                            >
                                                <div className='exchange-form-select-name'>
                                                    {fromCurrency ? (
                                                        <>
                                                            <img src={fromCurrency.image} alt={fromCurrency.name} width="20" />
                                                            {fromCurrency.name} {fromCurrency.network}
                                                        </>
                                                    ) : 'Выберите валюту'}
                                                </div>
                                                <span className="exchange-form-select-arrow">
                                                    <img
                                                        src={isFromSelectOpen ? arrowUpImg : arrowDownImg}
                                                        alt={isFromSelectOpen ? 'Скрыть' : 'Показать'}
                                                    />
                                                </span>
                                            </div>
                                            {isFromSelectOpen && (
                                                <div className="exchange-form-select-options">
                                                    {coins.map(coin => (
                                                        <div
                                                            key={coin.id}
                                                            className={`exchange-form-select-option ${toCurrency && toCurrency.id === coin.id ? 'disabled' : ''}`}
                                                            onClick={() => {
                                                                if (toCurrency && toCurrency.id === coin.id) {
                                                                    return;
                                                                }
                                                                setFromCurrency(coin);
                                                                setIsFromSelectOpen(false);
                                                                setFromAmount('');
                                                                setToAmount('');
                                                                fromInputRef.current.focus();
                                                            }}
                                                        >
                                                            <img src={coin.image} alt={coin.name} />
                                                            {coin.name} {coin.network}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <input
                                            type="text"
                                            name="fromAmount"
                                            className={`exchange-form-input ${!isFromAmountValid ? 'exchange-form-input-error' : ''}`}
                                            placeholder={!isFromAmountValid ? 'Укажите сумму' : 'Введите сумму'}
                                            value={fromAmount}
                                            onChange={handleInputChange}
                                            ref={fromInputRef}
                                            required
                                        />
                                    </div>
                                </div>
                                {/* Блок 2: Получаете */}
                                <div className="exchange-form-block">
                                    <h3>Получаете</h3>
                                    <div className="exchange-form-row">
                                        <div className="exchange-form-select exchange-form-select2">
                                            <div
                                                className="exchange-form-select-header"
                                                onClick={() => setIsToSelectOpen(!isToSelectOpen)}
                                            >
                                                <div className='exchange-form-select-name'>
                                                    {toCurrency ? (
                                                        <>
                                                            <img src={toCurrency.image} alt={toCurrency.name} width="20" />
                                                            {toCurrency.name} {toCurrency.network}
                                                        </>
                                                    ) : 'Выберите валюту'}
                                                </div>
                                                <span className="exchange-form-select-arrow">
                                                    <img
                                                        src={isToSelectOpen ? arrowUpImg : arrowDownImg}
                                                        alt={isToSelectOpen ? 'Скрыть' : 'Показать'}
                                                        width="20"
                                                    />
                                                </span>
                                            </div>
                                            {isToSelectOpen && (
                                                <div className="exchange-form-select-options">
                                                    {coins.map(coin => (
                                                        <div
                                                            key={coin.id}
                                                            className={`exchange-form-select-option ${fromCurrency && fromCurrency.id === coin.id ? 'disabled' : ''}`}
                                                            onClick={() => {
                                                                if (fromCurrency && fromCurrency.id === coin.id) {
                                                                    return;
                                                                }
                                                                setToCurrency(coin);
                                                                setIsToSelectOpen(false);
                                                                setFromAmount('');
                                                                setToAmount('');
                                                                fromInputRef.current.focus();
                                                            }}
                                                        >
                                                            <img src={coin.image} alt={coin.name} />
                                                            {coin.name} {coin.network}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <input
                                            type="text"
                                            name="toAmount"
                                            className="exchange-form-input"
                                            placeholder="Получите сумму"
                                            value={toAmount}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                {/* Блок 3: Кнопка обмена и курс */}
                                <div className="exchange-form-block">
                                    <div className="exchange-form-row">
                                        <button
                                            type="button"
                                            className="button exchange-form-button"
                                            onClick={handleExchangeClick}
                                        >
                                            Обмен
                                        </button>
                                        <div className="exchange-form-rate">
                                            {exchangeRate ? (
                                                `Курс обмена: 1 ${fromCurrency?.symbol.toUpperCase()} = ${exchangeRate.toFixed(5)} ${toCurrency?.symbol.toUpperCase()}`
                                            ) : (
                                                <div className='exchange-form-rate-loading'>
                                                    Загрузка курса...
                                                    <div className='exchange-form-rate-spinner'></div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <BlockHow
                            items={exchangeHow}
                        />
                        <div className='exchange-telegram'>
                            <div className='exchange-telegram-body'>
                                <div className='exchange-telegram-descr'>
                                    <h3 className='exchange-telegram-title title-3'>Telegram бот ImpEx</h3>
                                    <p className='text'>
                                        Просто откройте Telegram и начните обмен в пару кликов. Наш бот работает 24/7, предлагая актуальные курсы и мгновенные транзакции также, как и на сайте. Присоединяйтесь и убедитесь, как легко можно управлять своими активами с помощью нашего бота!
                                    </p>
                                    <div className='exchange-telegram-descr-bottom'>
                                        <a class="button exchange-telegram-button" href="https://t.me/impexchangebot" target="_blank" rel="noreferrer">Перейти в telegram-бот</a>
                                    </div>
                                </div>
                                <div className='exchange-telegram-image'>
                                    <iframe 
                                        src="https://lottie.host/embed/872239f4-0794-44e9-aa62-0bcc8faf5388/lSjLoRKSg7.json"
                                        title="Telegram Frame"
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                        <BlockInfo
                            items={aboutExchangeBlock}
                        />
                        <LastTradesChart/>
                        <div className='exchange-offer'>
                            <Offer
                                title="Что МЫ предлагаем"
                                items={offerItems}
                            />
                        </div>
                        <BlockInfo
                            items={fastExchangeBlock}
                        />
                    </div>
                )}
            </div>
        </section>
    );
};

export default Exchange;