import './offer.scss'

const Offer = ({ title, items }) => {
    return (
        <div className='offer'>
            <h3 className='offer-title title-3'>{title}</h3>
            <ul className='offer-list'>
                {items.map((item, index) => (
                    <li key={index} className='offer-item'>
                        <div className='offer-item-img'>
                            <img src={item.img} alt="иконка" />
                        </div>
                        <h4 className='offer-item-title'>{item.title}</h4>
                        <p className='offer-item-text'>{item.text}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Offer
