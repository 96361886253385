import { useContext, useEffect, useState  } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../../context/AuthContext'
import { auth } from '../../../services/firebaseConfig'
import { signOut } from 'firebase/auth'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { db } from '../../../services/firebaseConfig'

import './accountHeader.scss'
import user from './../../../resources/images/account/user.png'

const AccountHeader = () => {
    const { email, setEmail, setIsAuthenticated } = useContext(AuthContext)
    const [totalEarn, setTotalEarn] = useState(0)
    const [dailyProfit, setDailyProfit] = useState(0)
    const [dailyPercentage, setDailyPercentage] = useState(0)
    const [registrationDate, setRegistrationDate] = useState(null);
    const navigate = useNavigate()
    // Функция для выхода из аккаунта
    const handleLogout = async () => {
        try {
            await signOut(auth)
            setIsAuthenticated(false)
            setEmail('')
            navigate('/')
        } catch (error) {
            console.error('Error during logout:', error)
        }
    };
    // Получение данных пользователя (общий заработок, ежедневная прибыль, процент, баланс, дату) из Firestore
    useEffect(() => {
        if (email) {
            const unsubscribe = onSnapshot(
            query(collection(db, 'users'), where('email', '==', email)),
            (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    setTotalEarn(doc.data().totalEarn)
                    setDailyProfit(doc.data().dailyProfit)
                    setDailyPercentage(doc.data().dailyPercentage)
                    setRegistrationDate(doc.data().registrationDate.toDate())
            })
        },
            (error) => {
              console.error('Error fetching user data:', error);
            },
        )
    
            return () => {
            unsubscribe()
            };
        }
    }, [email])

    // функция для форматирования даты
    const formatDate = (date) => {
        if (!date) return "";
    
        const formatter = new Intl.DateTimeFormat("ru-RU", {
            day: "2-digit",
            month: "long",
            year: "numeric",
        });
    
        return formatter.format(date);
    };

    return(
        <div className="account-header">
            <div className="account-header-wrapper">
            <div>
                <div className="account-header-login">
                    <img src={user} alt="user" />
                    <h3 className="account-header-nickname">{ email }</h3>
                    <button
                        onClick={handleLogout} 
                        className="button account-header-button"
                    >
                        выйти
                    </button>
                </div>
                <div className="account-header-date">
                    <h3 className="account-header-date-title">Дата регистрации:</h3>
                    <p className="account-header-date-number">{formatDate(registrationDate)}</p>
                </div>
            </div>
                <div className="account-header-static">
                    <div className="account-header-static-item">
                        <h3 className="account-header-static-title">Заработок&nbsp;всего:</h3>
                        <p className="account-header-static-amount">{totalEarn.toFixed(2)}$</p>
                    </div>
                    <div className="account-header-static-item">
                        <h3 className="account-header-static-title">Заработок за сегодня:</h3>
                        <div className="account-header-static-daily">
                            <p className="account-header-static-amount account-header-static-amount-daily">{dailyProfit.toFixed(2)}$</p>
                            <p className="account-header-static-amount account-header-static-amount-daily">{dailyPercentage.toFixed(2)}%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountHeader