import { useState } from 'react';
import sendMessageToTelegram from '../../../services/telegramService'

import './paymentProcessing.scss'
import copy from './../../../resources/images/icons/copy-color.png'
import number3 from './../../../resources/images/icons/number-3.png'
import number4 from './../../../resources/images/icons/number-4.png'
import thumbUp from './../../../resources/images/icons/thumb-up.png'

const PaymentProcessing = ({ amount, selectedPaymentSystem, onPrevStep }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        let message = `<b>Новый депозит</b>\n`;
        message += `<b>ФИО:</b> ${e.target.name.value}\n`;
        message += `<b>Никнейм:</b> ${e.target.telegram.value}\n`;
        message += `<b>Платежная система:</b> ${selectedPaymentSystem.title}\n`;
        message += `<b>Сумма платежа:</b> ${amount}\n`;
    
        await sendMessageToTelegram(message)
        setIsModalVisible(true);

        setTimeout(() => {
            setIsModalVisible(false);
            onPrevStep();
        }, 7000);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => {
                alert(`Скопировано в буфер обмена: ${text}`);
            },
            (err) => {
                console.error('Не удалось скопировать текст:', err);
            }
        );
    };

    return (
        <div className="payment-processing">
            <button 
                onClick={onPrevStep}
                className="button payment-processing-back"
            >
                Вернуться назад
            </button>
            <div className="topup-step">
                <div className="topup-step-header">
                    <img src={number3} alt="step 3" />
                    <h5 className="text">Скопируйте наши реквизиты <span>{selectedPaymentSystem.title}</span> и выполните перевод средств по ним. Данная операция зачисления средств 
                    производится в автоматическом режиме (24/7). Заявка считается оплаченной после получения 20 подтверждений от сети.</h5>
                </div>
                <p className="text payment-processing-sum">Сумма платежа: <span>{ amount }$</span></p>
                <div className="payment-processing-details">
                    <img src={ selectedPaymentSystem.src } alt="payment" />
                    <div className="payment-processing-field">
                        <div className="payment-processing-address">{ selectedPaymentSystem.address }</div>
                        <button
                            onClick={() => copyToClipboard(selectedPaymentSystem.address)}
                            class="payment-processing-copy"
                        >
                            <span class="payment-processing-copy-text">копировать</span>
                            <img src={ copy } alt="copy"/>
                        </button>
                    </div>
                </div>
            </div>
            <div className="topup-step">
                <div className="topup-step-header">
                    <img src={number4} alt="step 4" />
                    <h5 className="text">Заполните корректно все поля представленной ниже формы. После выполнения всех действий нажмите кнопку "Пополнить баланс"</h5>
                </div>

                <div className="payment-processing-form-wrapper">
                    {!isModalVisible && (
                        <form 
                            className="payment-processing-form" 
                            onSubmit={ handleSubmit }
                            action="telegram.php" 
                            method="POST"
                        >
                            <label className="payment-processing-form-label">
                                <span className="payment-processing-form-caption">ФИО</span>
                                <input className="payment-processing-form-input" type="text" name="name" required/>
                            </label>
                            <label className="payment-processing-form-label">
                                <span className="payment-processing-form-caption">
                                    Никнейм Telegram
                                </span>
                                <input className="payment-processing-form-input" type="text" name="telegram" required/>
                            </label>
                            <button className="payment-processing-form-button button" type="submit">Пополнить баланс</button>
                        </form>
                    )}
                    {isModalVisible && (
                        <div className="payment-processing-modal">
                            <div className="payment-processing-modal-icon">
                                <img src={ thumbUp } alt="modal-icon"/>
                            </div>
                            <h3 className="payment-processing-modal-title">Ваша заявка на открытие счёта принята!</h3>
                            <p className="payment-processing-modal-text">После подтверждения транзакции наши менеджеры свяжутся с Вами в telegram.</p>
                            <p className="payment-processing-modal-text">Спасибо за сотрудничество!</p>
                        </div> 
                    )}
                </div>
            </div>
        </div>
    )
}

export default PaymentProcessing