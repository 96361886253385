import './../styles/components/faq.scss'
import TitleSection from "../components/titleSection/TitleSection"
import FaqList from './../components/faqList/FaqList'

const FAQ = () => {
    return (
        <section className="faq">
            <div className="container">
                <div className="faq-wrapper">
                    <TitleSection
                    title='Часто задаваемые вопросы'
                    subtitle="Здесь вы найдете ответы на часто задаваемые вопросы, чтобы облегчить ваше взаимодействие с нашей платформой"
                    />
                    <FaqList/>
                </div>
            </div>
        </section>
    )
}

export default FAQ