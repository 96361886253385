import { Routes, Route } from 'react-router-dom'

import AccountNav from '../components/account/accountNav/AccountNav'
import AccountHeader from '../components/account/accountHeader/AccountHeader'
import AccountProfile from '../components/account/accountProfile/AccountProfile'
import AccountPartners from '../components/account/accountPartners/AccountPartners'
import AccountTopUpBalance from '../components/account/accountTopUpBalance/AccountTopUpBalance'
import AccountDealCalendar from '../components/account/accountDealCalendar/AccountDealCalendar'
import AccountDeposit from '../components/account/accountDeposit/AccountDeposit'
import AccountWithdrawal from '../components/account/accountWithdrawal/AccountWithdrawal'

const Account = () => {

    return (
        <div className="account">
            <div className="container">
                <div className="account-wrapper">
                    <AccountNav/>
                    <AccountHeader/>
                    <div className="account-body">
                        <Routes>
                            <Route index element={<AccountProfile />} />
                            <Route path="/profile" element={<AccountProfile />} />
                            <Route path="/deposit" element={<AccountDeposit />} />
                            <Route path="/topup" element={<AccountTopUpBalance  />} />
                            <Route path="/withdrawal" element={<AccountWithdrawal  />} />
                            <Route path="/partners" element={<AccountPartners />} />
                            <Route path="/calendar" element={<AccountDealCalendar />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
};
  
export default Account