import './blockHow.scss';
 
const BlockHow = ( {items} ) => {
    return(
        <div className="how">
            <div className="how-header">
                <h3 className="how-title title-3">Как это работает</h3>
            </div>
            <div className="how-list">
                {
                items.map((item, index) => {
                    return (
                        <article key={index} className={item.class ? 'how-item' : 'how-item trading-item'} data-aos="zoom-in" data-aos-duration="1000" data-aos-delay={index * 300}>
                            <div className="how-item-icon">
                                <img src={item.icon} alt="how-icon"/>
                            </div>
                                <h4 className="how-item-title" dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                                <p className="how-item-text" dangerouslySetInnerHTML={{ __html: item.text }}></p>
                        </article>
                    )
                })
                }
            </div>
        </div>
    )
}

export default BlockHow;