import { useState } from 'react';
import AccountTitle from '../accountTitle/AccountTitle'
import Select from 'react-select';
import './accountWithdrawal.scss'

const AccountWithdrawal = () => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (option) => {
      setSelectedOption(option);
    };

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          color: '#fff', // Цвет текста опций
          backgroundColor: 'rgba(23, 35, 51, 1)', // Цвет фона опций
          ':hover': {
            backgroundColor: '#2C90F7', // Цвет фона при наведении курсора
          },
          fontSize: '1rem', // Размер шрифта опций
          lineHeight: 'calc(25 / 16)', // Межстрочный интервал опций
        }),
        control: (provided) => ({
          ...provided,
          height: '3rem', // Высота контрола
          background: 'rgba(255, 255, 255, 0.06)', // Цвет фона контрола
          boxShadow: 'inset 0px 1px 2px rgba(239, 232, 232, 0.6)', // Тень контрола
          backdropFilter: 'blur(1.5px)', // Эффект размытия для контрола
          borderRadius: '15px', // Скругление углов контрола
          padding: '0 5px', // Отступы внутри контрола
          transition: 'all 0.3s ease', // Плавный переход для всех стилей контрола
          borderColor: '#f2994a', // Цвет границы контрола
          '&:hover': {
            borderColor: '#f2994a', // Цвет границы контрола при наведении курсора
          },
          color: '#fff', // Цвет текста контрола
        }),
        menu: (provided) => ({
            ...provided,
            paddingTop: 0, // Убрать верхний отступ у списка
            paddingBottom: 0, // Убрать нижний отступ у списка
            background: 'rgba(255, 255, 255, 1)',// Цвет фона выпадающего списка
            position: 'absolute', // Добавьте абсолютное позиционирование
            width: '100%', 
            // bottom: 0,
            zIndex: 1000,
            maxHeight: '500px', // Установите максимальную высоту для меню
        
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#fff', // Цвет текста выбранного значения
        }),
      };
  

    const paymentOptions = [
        { value: 'bitcoin', label: 'Bitcoin' },
        { value: 'ethereum', label: 'Ethereum' },
        { value: 'tether_trc20', label: 'Tether trc20' },
        { value: 'Tether_erc20', label: 'Tether erc20' },
        { value: 'Tron', label: 'Tron' },
        { value: 'Perfect_money', label: 'Perfect money' },
        { value: 'Payeer', label: 'Payeer' },
        { value: 'QIWI', label: 'QIWI' },
        { value: 'Visa_MasterCard', label: 'Visa/MasterCard' },
        { value: 'Banks', label: 'Другие банки' },
      ];
    return (
        <div className="withdrawal">
            <div className="container">
                <AccountTitle
                    title="Вывод прибыли"
                />
                <div className="withdrawal-body">
                    <ul className="withdrawal-list">
                        <li className="withdrawal-item text">Комиссия при выводе средств - 0%</li>
                        <li className="withdrawal-item text">Вывод прибыли на любую криптовалюту - от 15$</li>
                        <li className="withdrawal-item text">Вывод прибыли на электронные кошельки - от 0.01$</li>
                        <li className="withdrawal-item text">Гарантированный срок зачисления средств на Ваши реквизиты - от мгновенного до 6 часов</li>
                        <li className="withdrawal-item text">Курс криптовалюты при выводе средств фиксируется на момент оформления заявки</li>
                        <li className="withdrawal-item text">Внимательно сверяйте выбранную платёжную систему и Ваши реквизиты. В случае указания неверных реквизитов наш сервис не несёт ответственности за потерю Ваших средств</li>
                        <li className="withdrawal-item text">В целях безопасности наш сервис проверяет все заявки и наши менеджеры могут связаться с Вами в Telegram для уточнения информации</li>
                        <li className="withdrawal-item text">Заявки обрабатываются нашим сервисом 24/7</li>
                    </ul>
                    <div className="withdrawal-form">
                        <label htmlFor="">
                            <span className="text">Введите сумму</span>
                            <input className="withdrawal-form-input" type="text" required/>
                        </label>
                        <label htmlFor="">
                            <span className="text">Выберите платёжную систему</span>
                                <Select
                                    className="withdrawal-form-select"
                                    value={selectedOption}
                                    onChange={handleChange}
                                    options={paymentOptions}
                                    styles={customStyles}
                                    menuPosition="fixed"
                                />
                        </label>
                        <label htmlFor="">
                            <span className="text">Укажите Ваши реквизиты</span>
                            <input className="withdrawal-form-input" type="text" required/>
                        </label>
                        <button className="button withdrawal-form-button">Заказать</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountWithdrawal