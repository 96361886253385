import './contactSocial.scss'

import telegramIcon from './../../resources/images/icons/telegram_icon.svg'

const ContactSocial = () => {
    const socialItem = [
        {
            href: 'https://t.me/ImperiumBroker_manager',
            src: telegramIcon,
            alt: "telegram",
            text: 'Техподдержка'
        },
        {
            href: 'https://t.me/ImperiumBroker_official',
            src: telegramIcon,
            alt: "telegram",
            text: 'Канал'
        },
        {
            href: 'https://t.me/impexchangebot',
            src: telegramIcon,
            alt: "telegram",
            text: 'Бот ImpEx'
        },
    ]

    return(
        <div className="social contact-social">
            <h3 className="social-title title-3">Подпишись на нас:</h3>
            <ul className="social-list">
                {
                    socialItem.map((item, index) => {
                        return(
                            <li className="social-item" key={index}>
                                <a className="social-link" href={item.href} target="_blank" rel="noreferrer">
                                    <img src={item.src} alt={item.alt}/>
                                    <span>{item.text}</span>
                                </a>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default ContactSocial