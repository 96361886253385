import { useEffect } from "react";
import './headerWidget.scss';

const HeaderWidget = () => {
    useEffect(() => {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        symbols: [
            {
            "proName": "BINANCE:BTCUSDT",
            "title": "Bitcoin"
            },
            {
            "proName": "BINANCE:ETHUSDT",
            "title": "Ethereum"
            },
            {
            "proName": "BINANCE:SOLUSDT",
            "title": "Solana"
            },
            {
            "proName": "BINANCE:DOGEUSDT",
            "title": "Dogecoin"
            },
            {
            "proName": "BINANCE:SUIUSDT",
            "title": "Sui"
            },
            {
            "proName": "BINANCE:XRPUSDT",
            "title": "XRP"
            },
            {
            "proName": "BINANCE:PEPEUSDT",
            "title": "Pepe"
            },
            {
            "proName": "BINANCE:AVAXUSDT",
            "title": "Avalanche"
            },
            {
            "proName": "BINANCE:BNBUSDT",
            "title": "Binance Coin"
            },
            {
            "proName": "BINANCE:SHIBUSDT",
            "title": "Shiba Inu"
            },
            {
            "proName": "BINANCE:NEARUSDT",
            "title": "NEAR Protocol"
            },
            {
            "proName": "BINANCE:LTCUSDT",
            "title": "Litecoin"
            },
            {
            "proName": "OKX:TONUSDT",
            "title": "Toncoin"
            },
            {
            "proName": "BINANCE:GALAUSDT",
            "title": "Gala"
            },
            {
            "proName": "BINANCE:XLMUSDT",
            "title": "Stellar"
            },
            {
            "proName": "COINBASE:AVAXUSD",
            "title": "Avalanche (USD)"
            },
            {
            "proName": "CRYPTO:XRPUSD",
            "title": "XRP (USD)"
            },
            {
            "proName": "BINANCE:POLUSDT",
            "title": "Polkadot"
            }
        ],
        showSymbolLogo: false,
        colorTheme: "dark",
        isTransparent: true,
        largeChartUrl: "https://www.imperiumbroker.pro/passive-income.html",
        displayMode: "adaptive",
        locale: "ru",
      });
  
      document.querySelector(".tradingview-widget-container__header-widget").appendChild(script);

    }, []);
  
    return (
        <div className="header-widget">
            <div className="tradingview-widget-container__header-widget"></div>
        </div>
    );
};

export default HeaderWidget;



