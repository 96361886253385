import './advantagesSection.scss';
import TitleSection from '../titleSection/TitleSection';


// import image01 from './../../resources/images/advantages/image01.png';
import icons01 from './../../resources/images/advantages/icons01.svg';
import icons02 from './../../resources/images/advantages/icons02.svg';
import icons03 from './../../resources/images/advantages/icons03.svg';
import icons04 from './../../resources/images/advantages/icons04.svg';

const AdvantagesSection = ( {items} ) => {

    const advantagesList = [
        {
            img: icons01,
            title: 'Высокие лимиты и скорость',
            text: `Мы предлагаем высокие лимиты на обменные операции и моментальное выполнение транзакций, что позволяет вам совершать крупные сделки быстро и без задержек.`
        },
        {
            img: icons02,
            title: 'Интуитивно понятный интерфейс',
            text: `Наша платформа проста в использовании, позволяя вам быстро и легко осуществлять обмен без лишних сложностей: на сайте или telegram-боте`
        },
        {
            img: icons03,
            title: 'Безопасность и доверие',
            text: `Поддержка современных технологий шифрования и защиты данных, отсутствие регистрации. Мы гарантируем анонимность и безопасность ваших средств.`
        },
        {
            img: icons04,
            title: 'Постоянная поддержка',
            text: `Команда профессионалов всегда готова ответить на ваши вопросы и помочь с любыми трудностями, несмотря на то, что все основные направления обмена производятся автоматически. Поддержка доступна 24/7.`
        }
    ]

    return(
        <section className="advantages">
            <div className="container">
                <div className="advantages-wrapper">
                    <TitleSection
                        title='<span>ImpEx</span> - Безграничный обмен криптовалют Web3.0'
                        subtitle="Осуществляйте обмен криптовалют на нашей платформе по самым выгодным и прозрачным курсам. Мы предлагаем удобные условия для пользователей, обеспечивая моментальное выполнение сделок и поддержку множества популярных активов. Независимо от объема операции, вы всегда получаете выгодный курс и максимальную защиту транзакций."
                    />
                    <div className="advantages-body">
                        <div className="advantages-image">
                            <iframe 
                            
                            src="https://lottie.host/embed/ffab960a-3d59-4888-9f71-61e41f44f77c/SxoNGpJUQh.json"
                            allowFullScreen
                            title="Advantages Animation"
                            ></iframe>
                        </div>
                        <div className="advantages-cards">
                            <div className="advantages-list">

                                {
                                    advantagesList.map((item, index) => {
                                        return(
                                            <article key={index} className="advantages-item">
                                                <div className="advantages-item-icon">
                                                    <img src={item.img} alt="advantages-icon"/>
                                                </div>
                                                <h4 className="advantages-item-title">{item.title}</h4>
                                                <p className="advantages-item-text">{item.text}</p>
                                            </article>
                                        )
                                    })
                                }

                            </div>
                            <div className="advantages-bottom">
                                <a className="advantages-button button" href="https://t.me/impexchangebot" target="_blank" rel="noreferrer">Перейти в Telegram-бот</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AdvantagesSection;