import TitleSection from "../components/titleSection/TitleSection"
import BlockInfo from "../components/blockInfo/BlockInfo"
import './../styles/components/team.scss'
import person1 from './../resources/images/team/ben.png'
import person2 from './../resources/images/team/jer.png'
import person3 from './../resources/images/team/ale.png'
import person4 from './../resources/images/team/oli.png'
import person5 from './../resources/images/team/eth.png'
import person6 from './../resources/images/team/sop.png'
import teamImg from './../resources/images/team/group-dynamics.png'

const personsList = [
    { 
        src: person1, 
        alt: 'person1', 
        name: 'Бенджамин Вебер',
        badge: 'Генеральный директор',
        text: `Бенджамин Вебер получил степень бакалавра по бизнесу в Гарвардской школе бизнеса и имеет богатый опыт работы 
        в сфере финансов и технологий. Перед тем, как основать ImperiumBroker, он работал в качестве финансового аналитика 
        в Goldman Sachs, где приобрел ценный опыт в области финансовых инвестиций.`
    },
    { 
        src: person2, 
        alt: 'person2', 
        name: 'Джерри Картер',
        badge: 'Главный технический директор',
        text: `Джерри Картер окончил Массачусетский технологический институт (MIT) по специальности информационные технологии. 
        Перед тем, как присоединиться к команде ImperiumBroker, он работал в качестве ведущего инженера в Google, 
        где занимался разработкой технологических решений для масштабных проектов.`
    },
    { 
        src: person3, 
        alt: 'person3', 
        name: 'Александр Рид',
        badge: 'Креативный директор',
        text: `Александр Рид получил степень магистра по дизайну в Королевском колледже искусств в Лондоне. 
        Он имеет богатый опыт работы в сфере дизайна и креатива. Перед присоединением к ImperiumBroker, 
        он работал в агентстве Wieden+Kennedy, где разрабатывал успешные маркетинговые кампании для крупных брендов.`
    },
    { 
        src: person4, 
        alt: 'person4', 
        name: 'Оливер Браун',
        badge: 'Старший менеджер по проектам',
        text: `Оливер Браун получил степень магистра по управлению проектами в Университете Стэнфорда. 
        Перед присоединением к ImperiumBroker, он работал в качестве старшего менеджера проектов на бирже HTX, 
        где успешно управлял реализацией новых технологических разработок.`

    },
    { 
        src: person5, 
        alt: 'person5', 
        name: 'Итан Томпсон',
        badge: 'Технический директор',
        text: `Итан Томпсон получил степень доктора по информационным технологиям в Массачусетском технологическом институте (MIT). 
        Он имеет многолетний опыт работы в области блокчейн-технологий и программной инженерии. 
        Перед присоединением к ImperiumBroker, он участвовал в разработки новых IT-решений для IBM.`
    },
    { 
        src: person6, 
        alt: 'person6', 
        name: 'София Эванс',
        badge: 'Директор по дизайну',
        text: `София Эванс окончила Школу дизайна в Лондоне и получила степень бакалавра по визуальным искусствам. 
        Перед присоединением к ImperiumBroker, она работала в лондонском филиале компании Apple, 
        где принимала участие в разработке уникальных пользовательских интерфейсов для различных продуктов компании.`
    }

];

const aboutTeamInfo = [
    {
        img: teamImg,
        title: 'ImperiumBroker — команда экспертов для вашего успеха',
        text: `Наша команда — это слаженный коллектив профессионалов с многолетним опытом в технологиях, финансах, 
        блокчейн-индустрии и креативе. Мы основали ImperiumBroker, чтобы предоставить нашим пользователям передовые инструменты для заработка на криптовалютах, 
        включая возможности инвестирования в стекинг. Также благодаря глубоким знаниям и опыту, мы запустили собственный криптообменник ImpEx, обеспечивая безопасные и 
        выгодные сделки с цифровыми активами. Наша цель — сделать криптовалюты доступными для каждого, создавая инновационные решения и помогая клиентам раскрывать потенциал цифровой экономики. 
        Мы ценим прозрачность, инновации и долгосрочные партнерства.`,
    },
]

const Team = () => {
    return (
        <section className="team">
            <div className="container">
                <TitleSection
                    title='Наша команда'
                    subtitle='Команда ImperiumBroker - это объединение профессионалов и криптоэнтузиастов, направленное на решение сложных блокчейн задач'
                />
                <div className="team-wrapper">
                    <div className="team-list">
                        {personsList.map((person, index) => (
                            <div className="team-item" key={index}>
                                <div className="team-item-top">
                                    <img className="team-item-photo" src={person.src} alt={person.alt} />
                                    <div className="team-item-name">{person.name}</div>
                                    <div className="team-item-badge">{person.badge}</div>
                                </div>
                                <div className="team-item-text text">
                                    <p>{person.text}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <BlockInfo items={ aboutTeamInfo }/>
                </div>
            </div>
        </section>
    )
}

export default Team