import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { useState, useEffect } from 'react'
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './services/firebaseConfig';
import AuthContext from './context/AuthContext'
import Header from "./components/header/Header"
import Footer from "./components/footer/Footer"
import Home from "./pages/Home"
import ContactsPage from "./pages/Contacts"
import Company from './pages/Company'
import FAQ from './pages/FAQ'
import News from './pages/News'
import Staking from './pages/Staking'
import Registration from './pages/Registration'
import Account from './pages/Account'
import Terms from './pages/Terms';
import Team from './pages/Team';
import Card from './pages/Card';
import Cashback from './pages/Cashback';
import Guide from './pages/Guide';
import Exchange from './pages/Exchange';
import Preloader from './components/preloader/Preloader';
import 'aos/dist/aos.css';
import AOS from 'aos';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [email, setEmail] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        AOS.init({
            once: true,
        });
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsAuthenticated(true)
                setEmail(user.email)
            } else {
                setIsAuthenticated(false)
                setEmail("")
            }
            setIsLoading(false)
        });

        return () => {
            unsubscribe()
        };
    }, [setIsAuthenticated, setEmail, setIsLoading])

    return (
        <div className="App">
            <AuthContext.Provider
                value={{
                    isAuthenticated,
                    setIsAuthenticated,
                    email,
                    setEmail,
                    isLoading,
                    setIsLoading,
                }}
            >
                <Router>
                    <AuthContext.Provider
                        value={{
                            isAuthenticated,
                            setIsAuthenticated,
                            email,
                            setEmail,
                            isLoading,
                            setIsLoading,
                        }}
                    >
                        {isLoading ? (
                            <Preloader />
                        ) : (
                            <>
                                <Header />
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/company" element={<Company />} />
                                    <Route path="/staking" element={<Staking />} />
                                    <Route path="/faq" element={<FAQ />} />
                                    <Route path="/news" element={<News />} />
                                    <Route path="/contacts" element={<ContactsPage />} />
                                    <Route path="/registration" element={<Registration />} />
                                    <Route path="/account/*" element={<Account />} />
                                    <Route path="/terms" element={<Terms />} />
                                    <Route path="/team" element={<Team />} />
                                    <Route path="/card" element={<Card />} />
                                    <Route path="/cashback" element={<Cashback />} />
                                    <Route path="/guide" element={<Guide />} />
                                    <Route path="/exchange" element={<Exchange />} />
                                </Routes>
                                <Footer />
                            </>
                        )}
                    </AuthContext.Provider>
                </Router>
            </AuthContext.Provider>
        </div>
    )
}

export default App;

