import { useEffect } from "react";
import './dealCalendarWidget.scss'

const DealCalendarWidget = () => {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        width: "100%",
        height: "100%",
        colorTheme: "dark",
        isTransparent: false,
        locale: "ru",
        importanceFilter: "-1,0,1",
        currencyFilter: "USD,EUR,ITL,NZD,CHF,AUD,FRF,JPY,ZAR,TRL,CAD,DEM,MXN,ESP,GBP"
      });
  
      const tradingViewWidgetContainer = document.querySelector(".widget-deal-calendar");
      tradingViewWidgetContainer.appendChild(script);
  
    }, []);
  
    return (
        <div className="deal-calendar-widget">
            {/* <h3 className="deal-calendar-widget-title">Ознакомьтесь с расписанием ключевых сделок ImperiumBroker</h3>
            <p className="deal-calendar-widget-text">С помощью данного виджета Вы можете удобно следить за предстоящими экономическими событиями, объявлениями и новостями.</p> */}
            <div className="widget-deal-calendar"></div>
        </div>
    );
  };
  
  export default DealCalendarWidget

