import { NavLink, useLocation } from 'react-router-dom';
import { useState, useRef, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import HeaderWidget from '../widgets/headerWidget/HeaderWidget';
import ArrowImg from './../../resources/images/header/down-arrow.png';
import './header.scss';

import logo from './../../resources/images/header/Logo.svg';

const Header = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuButtonActive, setMenuButtonActive] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [earningsDropdownOpen, setEarningsDropdownOpen] = useState(false);

    const navMenuRef = useRef(null);
    const menuButtonRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 992); // Проверка, мобильное устройство или нет
        };

        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener('resize', checkMobile);
        window.addEventListener('scroll', handleScroll);
        checkMobile();

        return () => {
            window.removeEventListener('resize', checkMobile);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Логика закрытия меню при клике вне его
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navMenuRef.current && !navMenuRef.current.contains(event.target) && !menuButtonRef.current.contains(event.target)) {
                setIsMenuOpen(false);
                setMenuButtonActive(false);
            }
        };

        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);

    const handleMenuButtonClick = () => {
        setIsMenuOpen(!isMenuOpen);
        setMenuButtonActive(!menuButtonActive);
    };

    const handleNavLinkClick = () => {
        setIsMenuOpen(false);
    };

    // Сброс состояния меню и кнопки при смене маршрута
    useEffect(() => {
        setIsMenuOpen(false);
        setMenuButtonActive(false);
    }, [location]);

    // Выпадающий список
    const [aboutDropdownOpen, setAboutDropdownOpen] = useState(false);
    const [moreDropdownOpen, setMoreDropdownOpen] = useState(false);

    const handleDropdownOpen = (setDropdownOpen) => {
        setDropdownOpen(true);
    };

    const handleDropdownClose = (setDropdownOpen) => {
        setDropdownOpen(false);
    };

    const handleMouseEnter = (setDropdownOpen) => {
        if (!isMobile) {
            handleDropdownOpen(setDropdownOpen);
        }
    };

    const handleMouseLeave = (setDropdownOpen) => {
        if (!isMobile) {
            handleDropdownClose(setDropdownOpen);
        }
    };

    const handleFocus = (setDropdownOpen) => {
        if (!isMobile) {
            handleDropdownOpen(setDropdownOpen);
        }
    };

    const handleBlur = (setDropdownOpen) => {
        if (!isMobile) {
            handleDropdownClose(setDropdownOpen);
        }
    };

    const handleClick = (setDropdownOpen) => {
        if (isMobile) {
            setDropdownOpen((prev) => !prev);
        }
    };

    // Закрытие меню при скролле
    useEffect(() => {
        if (scrollPosition > 0) {
            setAboutDropdownOpen(false);
            setMoreDropdownOpen(false);
        }
    }, [scrollPosition]);

    return (
        <header className="header">
            <HeaderWidget />
            <div className="container">
                <div className="header-wrapper">
                    <div className="header-logo logo">
                        <NavLink to="/">
                            <img src={logo} alt="logo" />
                        </NavLink>
                    </div>
                    <nav className={`nav ${isMenuOpen ? 'active' : ''}`} data-menu ref={navMenuRef}>
                        <ul className="nav-list">
                            <li className="nav-item">
                                <NavLink onClick={handleNavLinkClick} to="/" className="nav-link">
                                    Главная
                                </NavLink>
                            </li>
                            <li
                                className="nav-item"
                                onMouseEnter={() => handleMouseEnter(setAboutDropdownOpen)}
                                onMouseLeave={() => handleMouseLeave(setAboutDropdownOpen)}
                                onFocus={() => handleFocus(setAboutDropdownOpen)}
                                onBlur={() => handleBlur(setAboutDropdownOpen)}
                                onClick={() => handleClick(setAboutDropdownOpen)}
                            >
                                <span className="nav-link">О нас</span>
                                <img className="nav-item-arrow" src={ArrowImg} alt="arrow" />
                                <div className={`dropdown-menu ${aboutDropdownOpen ? 'show' : ''}`}>
                                    <NavLink onClick={handleNavLinkClick} to="/company" className="dropdown-item">
                                        Компания
                                    </NavLink>
                                    <NavLink onClick={handleNavLinkClick} to="/team" className="dropdown-item">
                                        Команда
                                    </NavLink>
                                </div>
                            </li>
                            <li
                                className="nav-item"
                                onMouseEnter={() => handleMouseEnter(setMoreDropdownOpen)}
                                onMouseLeave={() => handleMouseLeave(setMoreDropdownOpen)}
                                onFocus={() => handleFocus(setMoreDropdownOpen)}
                                onBlur={() => handleBlur(setMoreDropdownOpen)}
                                onClick={() => handleClick(setMoreDropdownOpen)}
                            >
                                <span className="nav-link">Подробнее</span>
                                <img className="nav-item-arrow" src={ArrowImg} alt="arrow" />
                                <div className={`dropdown-menu ${moreDropdownOpen ? 'show' : ''}`}>
                                    <NavLink onClick={handleNavLinkClick} to="/faq" className="dropdown-item">
                                        FAQ
                                    </NavLink>
                                    <NavLink onClick={handleNavLinkClick} to="/guide" className="dropdown-item">
                                        Руководство
                                    </NavLink>
                                    <NavLink onClick={handleNavLinkClick} to="./news" className="dropdown-item">
                                        Новости
                                    </NavLink>
                                </div>
                            </li>                           
                            <li
                                className="nav-item"
                                onMouseEnter={() => handleMouseEnter(setEarningsDropdownOpen)}
                                onMouseLeave={() => handleMouseLeave(setEarningsDropdownOpen)}
                                onFocus={() => handleFocus(setEarningsDropdownOpen)}
                                onBlur={() => handleBlur(setEarningsDropdownOpen)}
                                onClick={() => handleClick(setEarningsDropdownOpen)}
                            >
                                <span className="nav-link">Заработок</span>
                                <img className="nav-item-arrow" src={ArrowImg} alt="arrow" />
                                <div className={`dropdown-menu ${earningsDropdownOpen ? 'show' : ''}`}>
                                    <NavLink onClick={handleNavLinkClick} to="/staking" className="dropdown-item">
                                        Стейкинг
                                    </NavLink>
                                    <NavLink onClick={handleNavLinkClick} to="/card" className="dropdown-item">
                                        Карта
                                    </NavLink>
                                    <NavLink onClick={handleNavLinkClick} to="/cashback" className="dropdown-item">
                                        Кэшбэк
                                    </NavLink>
                                </div>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={handleNavLinkClick} to="/exchange" className="nav-link">
                                    Обменник
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={handleNavLinkClick} to="/contacts" className="nav-link">
                                    Контакты
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={isAuthenticated ? '/account' : '/registration'} className="button nav-button">
                                    {isAuthenticated ? 'Личный кабинет' : 'Вход'}
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                    <button
                        onClick={handleMenuButtonClick}
                        ref={menuButtonRef}
                        className={`menu-icon ${menuButtonActive ? 'active' : ''}`}
                        type="button"
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;