import './../styles/components/terms.scss'
import TitleSection from "../components/titleSection/TitleSection"

const Terms = () => {
    return (
        <section className="terms">
            <div className="container">
                <TitleSection
                    title='Условия использования'
                    subtitle='Эти условия регулируют ваше использование нашей стейкинг-платформы и сервиса. Пожалуйста, ознакомьтесь с ними внимательно перед началом работы. Использование сайта означает ваше согласие с данными условиями.'
                />
                <div className="terms-wrapper">
                    <div className="terms-block">
                        <h3 className="terms-title title-3">Общее</h3>
                        <p className="terms-text text">
                        ImperiumBroker и его аффилированные лица (далее ImperiumBroker, «мы», «нас» или «наш») стремятся защищать и уважать вашу конфиденциальность. 
                        Настоящая Политика конфиденциальности (вместе с нашими Условиями использования) регулирует сбор, обработку и использование вашей личной информации. 
                        Мы определяем «Личную информацию» как информацию, которая идентифицирует вас лично, например, ваше имя, адрес, адрес электронной почты, сделки и т. д.
                        </p>
                    </div>
                    <div className="terms-block">
                        <h3 className="terms-title title-3">Типы личной информации, которую мы собираем, могут включать:</h3>
                        <ul className="terms-text text">
                            <li>1. ваше имя;</li>
                            <li>2. фотографию вашего удостоверения личности;</li>
                            <li>3. данные из ваших документов, удостоверяющих личность (например, водительские права, паспорт), номер документа, дата выдачи и срок действия, удостоверение личности с фотографией, адрес и т. д .;</li>
                            <li>4. ваш адрес;</li>
                            <li>5. вашу электронную почту;</li>
                            <li>6. ваши банковские реквизиты, включая номера счетов;</li>
                            <li>7. вашу электронную почту;</li>
                            <li>8. вашу дату рождения;</li>
                            <li>9. и ваши сделки.</li>
                        </ul>
                        <p className="terms-text text">
                        Мы будем обрабатывать вашу Личную информацию только для целей предоставления вам услуг, которые вы просите нас предоставить, 
                        и для выполнения юридических обязательств, вытекающих из нормативных обязательств, возникающих в связи с предоставлением вам услуг.
                        </p>
                    </div>
                    <div className="terms-block">
                        <h3 className="terms-title title-3">Мы можем использовать вашу личную информацию в следующих целях:</h3>
                        <ul className="terms-text text">
                            <li>1. чтобы позволить вам открыть и управлять Учетной записью на Платформе;</li>
                            <li>2. чтобы вы могли совершать Транзакции на Платформе;</li>
                            <li>3. если вы обращаетесь к нам, чтобы получить ответы на ваши вопросы;</li>
                            <li>4. для анализа использования нашего сайта;</li>
                            <li>5. в соответствии с нормативными требованиями, такими как налогообложение, предотвращение отмывания денег, предотвращение мошенничества, соблюдение обязательств Компании по статистической отчетности и т. д .;</li>
                            <li>6. для предоставления вам информации о продуктах и ​​рекламных акциях, которые могут вас заинтересовать, от нас самих и от третьих лиц, но только в том случае, если вы намеренно согласились на получение такой информации;</li>
                            <li>7. для исследования рынка, например, для изучения потребностей и мнений наших пользователей по таким вопросам, как наша работа и т. д. Если не будет согласовано, ваши данные для этой цели будут анонимными.</li>
                        </ul>
                    </div>
                    <div className="terms-block">
                        <h3 className="terms-title title-3">IP-адреса</h3>
                        <p className="terms-text text">
                        Мы можем собирать информацию о вашем компьютере, включая, где это возможно, ваш IP-адрес, операционную систему и тип браузера, для системного администрирования и для предоставления совокупной информации нашим рекламодателям. 
                        Это статистические данные о действиях и шаблонах просмотра наших пользователей, и они не будут использоваться для идентификации какого-либо лица, за исключением этого человека.
                        </p>
                    </div>
                    <div className="terms-block">
                        <h3 className="terms-title title-3">Cookies</h3>
                        <p className="terms-text text">
                        Мы используем функцию браузера, известную как «cookie», которая присваивает вашему компьютеру уникальную идентификацию. Файлы cookie обычно хранятся на жестком диске вашего компьютера. 
                        Информация, собранная из файлов cookie, используется нами для оценки эффективности нашего Сайта, анализа тенденций и администрирования Платформы. Информация, собранная с помощью файлов cookie, 
                        позволяет нам определять, какие части нашего сайта наиболее посещаются и какие трудности могут возникнуть у наших посетителей при доступе к нашему сайту. Обладая этими знаниями, 
                        мы можем улучшить качество вашего взаимодействия с Платформой, распознавая и предоставляя больше наиболее желаемых функций и информации, а также устраняя трудности с доступом. 
                        Мы также используем файлы cookie и/или технологию, известную как веб-жучки четкие гифки, которые обычно хранятся в электронных письмах, чтобы помочь нам подтвердить получение вами наших писем и ответ на них, 
                        а также предоставить вам более персонализированный опыт использования нашего Сайта. . Мы пользуемся услугами сторонних поставщиков услуг, чтобы помочь нам лучше понять использование нашего Сайта. 
                        Наши поставщики услуг будут размещать файлы cookie на жестком диске вашего компьютера и получать выбранную нами информацию, которая знакомит нас с такими вещами, как навигация посетителей по нашему сайту, 
                        просматриваемые продукты и общая информация о транзакциях. Наши поставщики услуг анализируют эту информацию и предоставляют нам сводные отчеты. Информация и анализ, предоставленные нашим поставщиком (-ами) услуг, 
                        будут использоваться, чтобы помочь нам лучше понять интересы наших посетителей на нашем Сайте и как лучше удовлетворить эти интересы. Информация, собираемая нашими поставщиками услуг, может быть связана и объединена с информацией, 
                        которую мы собираем о вас, пока вы используете Платформу. Нашему поставщику услуг по контракту запрещено использовать информацию, которую они получают с нашего Сайта, кроме как для помощи нам. Используя наш Сайт, вы соглашаетесь с тем, 
                        что мы можем использовать файлы cookie для целей, изложенных выше. Компания будет вести учет всех передач Персональной информации третьим лицам, и эта информация, где это возможно, может быть предоставлена ​​вам.
                        </p>
                    </div>
                    <div className="terms-block">
                        <h3 className="terms-title title-3">Раскрытие личной информации</h3>
                        <p className="terms-text text">
                        Мы используем Личную информацию для целей, указанных в то время, когда вы предоставляете нам такую ​​информацию, и/или иным образом для целей, изложенных в настоящей Политике конфиденциальности и/или в иных случаях, разрешенных законом. 
                        Мы можем предоставлять Личную информацию, которую вы предоставляете нам, нашим аффилированным лицам, агентам, представителям, доверенным поставщикам услуг и подрядчикам для этих ограниченных целей с вашего явного согласия или для и во время предоставления услуги, 
                        которую вы бы запросили. Мы также можем передавать Личную информацию пользователей финансовым учреждениям, страховым компаниям или другим компаниям в случае слияния, разделения или другой корпоративной реорганизации и уведомлять вас о таком обмене вашей информацией, 
                        чтобы иметь возможность реализовать любое из ваших прав, где применимо. Мы также можем передавать Личную информацию пользователей правоохранительным или регулирующим органам, как это может потребоваться по закону. Мы не сможем сообщить вам о таком обмене данными из-за юридических ограничений. 
                        Любая третья сторона, которая получает или имеет доступ к Личной информации, должна защищать такую ​​Личную информацию и использовать ее только для оказания услуг, которые они предоставляют для вас или для ImperiumBroker, если иное не требуется или не разрешено законом. 
                        Такая третья сторона, за исключением регулирующих органов, будет по контракту обязана придерживаться тех же политик безопасности и конфиденциальности, что и ImperiumBroker, и брать на себя те же обязанности, что и ImperiumBroker. 
                        О законном осуществлении любых ваших прав в отношении ImperiumBroker также будет сообщено о применении любыми такими третьими сторонами, получившими доступ к вашей Личной информации. Мы гарантируем, что любая такая третья сторона осведомлена о наших обязательствах в соответствии с настоящей Политикой конфиденциальности, 
                        и мы будем заключать контракты с такими третьими сторонами, по которым они будут связаны условиями, не менее защищающими любую раскрываемую им Персональную информацию, чем обязательства, которые мы берем на себя. вы в соответствии с настоящей Политикой конфиденциальности или которые налагаются на нас в соответствии с действующим законодательством о защите данных.
                        </p>
                    </div>
                    <div className="terms-block">
                        <h3 className="terms-title title-3">Ваши права</h3>
                        <p className="terms-text text">
                        Вы имеете право получить доступ к своей Личной информации и потребовать исправления, обновления и блокировки неточных и/или неверных данных, отправив нам электронное письмо по электронной почте, указанной на веб-сайте (из электронной почты), вы можете сделать это действия на странице профиля вашей учетной записи (если применимо). 
                        По вашему письменному запросу на нашу электронную почту мы сообщим вам относящуюся к вам Личную информацию, которую мы храним, а также об использовании и общем раскрытии вашей Личной информации. Мы также предоставим вам копию Личной информации, которую мы сохранили. За предоставление дополнительных копий вашей личной информации для покрытия административных расходов может взиматься минимальная плата. 
                        Вы также можете запросить удаление или уничтожение как Учетной записи, так и Личной информации, отправив электронное письмо на нашу электронную почту. Qmall незамедлительно отреагирует на ваш запрос, за исключением случаев, когда это не соответствует ее юридическим и нормативным обязательствам. Вы также можете попросить нас передать вашу Личную информацию другому контроллеру по вашему выбору. 
                        Чтобы обеспечить конфиденциальность, целостность и доступность вашей информации для вас, мы можем попросить вас подтвердить свою личность, предоставив документы, удостоверяющие личность, и/или другие методы, прежде чем помогать вам в осуществлении любого из ваших прав. Если вы откажетесь подтвердить свою личность, мы можем отказаться принимать меры в отношении ваших данных, за исключением ограничений обработки, до тех пор, 
                        пока мы не сможем убедиться, что такие действия являются истинным желанием субъекта данных. При предоставлении наших услуг мы можем использовать автоматизированную обработку и профилирование, чтобы снизить риски мошенничества, отмывания денег и злоупотребления нашими услугами. С помощью этой автоматизированной обработки мы проводим анализ вашей идентификации, транзакционных и поведенческих моделей. Мы не сможем предоставить вам некоторые или все наши услуги, 
                        если вы не хотите, чтобы эта автоматическая обработка выполнялась. Если вы считаете, что эта обработка может быть вредной для вас, мы можем предоставить вам дополнительную информацию об этой автоматической обработке, связавшись с нами по электронной почте.
                        </p>
                    </div>
                    <div className="terms-block">
                        <h3 className="terms-title title-3">Ссылки</h3>
                        <p className="terms-text text">
                        Здесь могут быть ссылки с нашего Сайта на другие сайты и ресурсы, предоставленные третьими сторонами. Настоящая Политика конфиденциальности применима только к нашему сайту. Для доступа к сторонним сайтам или источникам вам необходимо покинуть наш Сайт. Мы не контролируем сторонние сайты или какой-либо контент, содержащийся на них, и вы соглашаетесь с тем, что мы никоим образом не несем ответственности за какие-либо из этих сторонних сайтов, включая, помимо прочего, 
                        их контент, политики, сбои, рекламные акции, продукты, услуги или действия и/или любые убытки, убытки, сбои или проблемы, вызванные, связанные с этими сайтами или связанные с ними. Мы рекомендуем вам ознакомиться со всеми политиками, правилами, условиями и положениями, включая политику конфиденциальности, каждого сайта, который вы посещаете.
                        </p>
                    </div>
                    <div className="terms-block">
                        <h3 className="terms-title title-3">Безопасность</h3>
                        <p className="terms-text text">
                        Мы внедрили технические и организационные меры безопасности для обеспечения конфиденциальности вашей личной информации и защиты вашей личной информации от потери, неправильного использования, изменения или уничтожения. Только уполномоченный персонал ImperiumBroker имеет доступ к вашей личной информации, и этот персонал должен обращаться с информацией как с конфиденциальной. 
                        Если вы дали согласие или мы обязаны передать Личную информацию третьим сторонам для оказания вам запрошенной услуги или при выполнении нормативных или юридических обязательств, мы будем запрашивать те же уровни технических и организационных мер безопасности. по возможности применяться в рамках договорных соглашений. Действующие технические и организационные меры безопасности будут время от времени пересматриваться в соответствии с юридическими и техническими разработками. 
                        В случае утечки персональных данных или отказа от мер защиты такой информации мы незамедлительно уведомим вас без неоправданной задержки.
                        </p>
                    </div>
                    <div className="terms-block">
                        <h3 className="terms-title title-3">Сохранение личной информации</h3>
                        <p className="terms-text text">
                        Ваша информация хранится на наших серверах. Доступ к этой информации предоставляется сотрудникам ImperiumBroker, офис которых может находиться внутри и/или за пределами Европейского Союза, все наши сотрудники придерживаются принципов безопасности данных и процессов, как и в Европейском Союзе. Мы будем хранить вашу Личную информацию только до тех пор, пока это необходимо для целей, описанных в настоящей Политике конфиденциальности, а также в наших собственных юридических и нормативных требованиях. 
                        В соответствии с юридическими обязательствами по борьбе с отмыванием денег, налогообложению и компании, мы будем хранить Учетные записи и Личную информацию как минимум в течение десяти лет после того, как пользователь закроет свою учетную запись у нас. Данные, хранящиеся только для нормативных целей, будут защищены от ненужной обработки и будут храниться только с целью предоставления информации или доступа к соответствующим органам.
                        </p>
                    </div>
                    <div className="terms-block">
                        <h3 className="terms-title title-3">Удаление персональных данных</h3>
                        <p className="terms-text text">
                        Если у нас не будет никаких обязательств по предоставлению вам запрошенной вами услуги или обязательств по хранению Персональной информации в нормативных или юридических целях, мы анонимизируем или удалим вашу Персональную информацию в соответствии с приемлемыми отраслевыми стандартами и стандартами безопасности, чтобы это не могло впоследствии быть извлечено и связано с вами.
                        </p>
                    </div>
                    <div className="terms-block">
                        <h3 className="terms-title title-3">Изменения</h3>
                        <p className="terms-text text">
                        Политика нашего Сайта, контент, информация, рекламные акции, раскрытие информации, дисклеймер и функции могут быть пересмотрены, изменены, обновлены и/или дополнены в любое время и без предварительного уведомления по единоличному и абсолютному усмотрению ImperiumBroker. Если мы изменим эту Политику конфиденциальности, 
                        мы предпримем шаги для уведомления всех пользователей посредством уведомления на нашем Сайте и опубликуем измененную Политику конфиденциальности на Сайте. Если мы сочтем, что любые такие изменения могут повлиять на ваши права, мы попросим вас подтвердить ваше рассмотрение и принятие, прежде чем продолжить наши отношения с вами.
                        </p>
                    </div>
                    <div className="terms-block">
                        <h3 className="terms-title title-3">Свяжитесь с нами</h3>
                        <p className="terms-text text">
                        Если у вас есть какие-либо вопросы, комментарии или опасения, не относящиеся конкретно к нашей Политике конфиденциальности и/или практике, относящейся к Платформе, пожалуйста, свяжитесь с нами по нашему адресу электронной почты.
                        </p>
                    </div>
                </div>
                <div className='terms-wrapper'>
                    <TitleSection
                        title='Политика AML'
                        subtitle='Забота о клиентах через соблюдение международных стандартов'
                    />
                    <ul className="terms-text text">
                        <li>1. Сервис ImperiumBroker придерживается практик и мер, в области противодействия легализации (отмыванию) доходов, полученных преступным путём и финансированию терроризма (AML).</li>
                        <li>2. Целью данных мер является демонстрация того, что ImperiumBroker относится серьёзно к любым попыткам использовать свой сервис в незаконных целях.</li>
                        <li>3. Сервис ImperiumBroker предостерегает пользователей от попыток использования сервиса ImperiumBroker для легализации денежных средств, полученных преступным путём, финансирование терроризма, мошенничества любого рода, а также от использования сервиса для покупки запрещённых товаров и услуг.</li>
                        <li>4. Для предотвращения операций незаконного характера Сервис устанавливает определенные требования ко всем Заявкам, создаваемым Пользователем:</li>
                        <ul className="terms-text text">
                            <li>4.1 Отправителем и получателем Платежа по Заявке должно быть одно и то же лицо. С использованием услуг Сервиса категорически запрещены переводы в пользу третьих лиц.</li>
                            <li>4.2. Все контактные данные, заносимые Пользователем в Заявку, а также иные персональные данные, передаваемые Пользователем Сервису, должны быть актуальными и полностью достоверными.</li>
                            <li>4.3. Категорически запрещено создание Заявок Пользователем с использованием анонимных прокси-серверов или каких-либо других анонимных подключений к Интернет.</li>
                        </ul>
                    </ul>
                </div>
            </div>
        </section>

    )

}

export default Terms