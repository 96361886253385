import { useState } from 'react';

import TitleSection from "../components/titleSection/TitleSection"
import BlockInfo from "../components/blockInfo/BlockInfo"
import TariffCard from "../components/tariffCard/TariffCard"
import ProfitCalculator from '../components/profitCalculator/ProfitCalculator';

import teamwork from './../resources/images/icons/teamwork.png'
import financial from './../resources/images/icons/financial.png'
import solImg from './../resources/images/staking/sol.png'
import ethImg from './../resources/images/staking/eth2.0.png'
import polImg from './../resources/images/staking/pol.jpeg'
import dydxImg from './../resources/images/staking/dydx.png'
import zetaImg from './../resources/images/staking/zeta.png'
import bandImg from './../resources/images/staking/band.png'
import sagaImg from './../resources/images/staking/saga.png'
import nearImg from './../resources/images/staking/near.png'
import mantaImg from './../resources/images/staking/manta.png';
import dymImg from './../resources/images/staking/dym.jpeg';
import osmoImg from './../resources/images/staking/osmo.png';
import iostImg from './../resources/images/staking/iost.png';
import coreImg from './../resources/images/staking/core.png';
import minaImg from './../resources/images/staking/mina.png';
import acaImg from './../resources/images/staking/aca.png';
import tonImg from './../resources/images/staking/ton.jpeg';
import adaImg from './../resources/images/staking/ada.png';
import iotxImg from './../resources/images/staking/iotx.png';
import tiaImg from './../resources/images/staking/tia.jpeg';
import avaxImg from './../resources/images/staking/avax.png';
import suiImg from './../resources/images/staking/sui.png';
import dotImg from './../resources/images/staking/dot.png';
import aptImg from './../resources/images/staking/apt.png';
import seiImg from './../resources/images/staking/sei.png';
import atomImg from './../resources/images/staking/atom.png';
import luncImg from './../resources/images/staking/lunc.png';
import injImg from './../resources/images/staking/inj.png';
import kavaImg from './../resources/images/staking/kava.png';
import roseImg from './../resources/images/staking/rose.png';
import cantoImg from './../resources/images/staking/canto.png';

const refferal = [
    {
        img: financial,
        title: 'Преимущества инвестирования в стейкинг',
        text: `Инвестирование в стейкинг — это эффективный способ получения стабильного пассивного дохода с минимальным риском. 
        Основное преимущество стейкинга заключается в том, что ваши активы продолжают работать на вас, даже когда рынок волатилен. 
        Вместо активной торговли, вы просто удерживаете свои средства на платформе, что позволяет избегать рыночных колебаний и временных потерь. 
        В стейкинге вы получаете регулярное начисление прибыли, а наши специалисты выбирают наиболее прибыльные криптовалюты для стейкинга. 
        Таким образом, вы обеспечиваете себе постоянный поток дохода без необходимости ежедневного мониторинга рынка.`
    },
    {
        img: teamwork,
        title: 'Реферальная программа',
        text: `Воспользуйтесь нашей реферальной программой и получайте дополнительный доход! 
        Привлекая новых пользователей, вы фиксированно зарабатываете 7% от суммы, которую инвестирует ваш партнёр, 
        при открытии тарифа. Каждый партнёр приносит реферальные начисления один раз, что позволяет вам строить свою сеть 
        и получать выгоду от рекомендаций. Если вы желаете зарабатывать на приглашении партнеров и у вас есть вопросы, обратитесь в службу заботы 
        о клиентах для получения детальной информации.`
    },
]

const products = [
    { title: 'sol', img: solImg, per: '7.00% ~ 10.00%' },
    { title: 'eth2.0', img: ethImg, per: '4.00%' },
    { title: 'pol', img: polImg, per: '4.00%' },
    { title: 'dydx', img: dydxImg, per: '8.90% ~ 14.00%' },
    { title: 'zeta', img: zetaImg, per: '10.00%' },
    { title: 'band', img: bandImg, per: '12.00%' },
    { title: 'saga', img: sagaImg, per: '9.00%' },
    { title: 'near', img: nearImg, per: '5.00%' },
    { title: 'manta', img: mantaImg, per: '14.00% ~ 20.00%' },
    { title: 'dym', img: dymImg, per: '8.00% ~ 13.80%' },
    { title: 'osmo', img: osmoImg, per: '8.00%' },
    { title: 'iost', img: iostImg, per: '4.00%' },
    { title: 'core', img: coreImg, per: '11.00% ~ 16.80%' },
    { title: 'mina', img: minaImg, per: '10.00% ~ 13.00%' },
    { title: 'aca', img: acaImg, per: '10.00%' },
    { title: 'ton', img: tonImg, per: '2.40% ~ 4.00%' },
    { title: 'ada', img: adaImg, per: '2.50%' },
    { title: 'iotx', img: iotxImg, per: '7.00% ~ 12.00%' },
    { title: 'tia', img: tiaImg, per: '10.00% ~ 11.00%' },
    { title: 'avax', img: avaxImg, per: '5.00%' },
    { title: 'sui', img: suiImg, per: '3.40%' },
    { title: 'dot', img: dotImg, per: '13.00%' },
    { title: 'apt', img: aptImg, per: '5.70%' },
    { title: 'sei', img: seiImg, per: '4.50%' },
    { title: 'atom', img: atomImg, per: '15.60% ~ 17.00%' },
    { title: 'lunc', img: luncImg, per: '5.00% ~ 8.00%' },
    { title: 'inj', img: injImg, per: '10.00%' },
    { title: 'kava', img: kavaImg, per: '5.00%' },
    { title: 'rose', img: roseImg, per: '4.50%' },
    { title: 'canto', img: cantoImg, per: '5.00%' },
];

const Staking = () => {

    const [visibleProducts, setVisibleProducts] = useState(8);
    const [showAll, setShowAll] = useState(false);

    const handleShowMore = () => {
        if (showAll) {
            setVisibleProducts(8);
            setShowAll(false);
        } else {
            const newVisibleProducts = Math.min(visibleProducts + 8, products.length);
            setVisibleProducts(newVisibleProducts);
            if (newVisibleProducts === products.length) {
                setShowAll(true);
            }
        }
    };

    return (
        <main>
            <section className="staking">
				<div className="container">
					<div className="staking-container">
						<div className="staking-wrapper">
                            <div className="staking-passive">
                            <TitleSection
                                title='Стейкинг'
                                subtitle="Получайте прибыль ежедневно, не рискуя основной суммой."
                                />
                                <div className="staking-passive-body">
                                    <h3 className="title-3">Тарифный план:</h3>
                                    <div className="staking-passive-list">
                                        <TariffCard
                                            title="Stake Pro"
                                            price="от 20$"
                                            termText='Срок депозита:'
                                            term="Бессрочно"
                                            features={[
                                            "Чистая прибыль: от 1.5% до 4% за 24 часа",
                                            "Начисление прибыли: через 24 часа с момента открытия счёта",
                                            "Вывод средств: возможен в любое время после 3 дней с момента открытия счёта.",
                                            "Кэшбэк от 2% в зависимости от инвестируемой суммы.",
                                            "Поддержка быстрых и гибких вариантов вывода для оптимизации стратегий стейкинга PoS.",
                                            "Гарантия возврата инвестируемой суммы",
                                            "Подробная статистика по Вашему счёту",
                                            "Техподдержка 24/7",
                                            "Не нужно выбирать криптовалюту: Наши специалисты сами подбирают оптимальные варианты для стейкинга, чтобы вы получали максимальную прибыль без лишних усилий."
                                            ]}
                                            buttonLink="https://t.me/ImperiumBroker_manager"
                                            buttonText='Получить консультацию'
                                            sale={false}
                                        />
                                    </div>
                                    <ProfitCalculator/>
                                    <BlockInfo 
                                    items={refferal}
                                    />
                                    <div className="products">
                                    <TitleSection
                                        title='Fixed to Flexible'
                                        subtitle="Средства, инвестированные в тарифный план Stake Pro, будут автоматически переведены в продукты с гибкими планами и разной доходностью, чтобы вы всегда были в плюсе. Заставьте свои деньги работать на вас!"
                                        />
                                        <ul className="products-list">
                                            {products.slice(0, visibleProducts).map((product, index) => (
                                                <li className="products-item" key={index}>
                                                    <h4 className="products-item-title">{product.title}</h4>
                                                    <div className="products-item-img">
                                                        <img src={product.img} alt={product.title} />
                                                    </div>
                                                    <p className="products-item-terms text">apr</p>
                                                    <p className="products-item-percent">{product.per}</p>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="products-btn">
                                            <button className="button products-btn-showmore" onClick={handleShowMore}>
                                                {showAll ? 'Скрыть' : 'Показать ещё'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>
				</div>
			</section>
		</main>
    )
}

export default Staking