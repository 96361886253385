import BlockInfo from "../components/blockInfo/BlockInfo"
import Documents from "../components/Documents/Documents"
import TitleSection from "../components/titleSection/TitleSection"
import image1 from "./../resources/images/company/exchange-rate.png"
import image2 from "./../resources/images/company/negotiation.png"
import image3 from "./../resources/images/company/insurance.png"


const Company = () => {
    const aboutBlockInfo = [
        {
            img: image1,
            title: 'Кто мы такие?',
            text: `Команда ImperiumBroker обладает высоким уровнем компетенции и опыта в сфере блокчейн-технологий и финансов, 
            что позволяет нам обеспечивать конкурентное преимущество на рынке стейкинг предложений. Мы объединяем лучших специалистов из разных областей: 
            разработчиков, аналитиков, финансовых экспертов и маркетологов, чтобы создать уникальную экосистему, способствующую росту и стабильности ваших инвестиций.
            Наша миссия заключается в предоставлении каждому пользователю удобного и безопасного доступа к миру стейкинга. Мы стремимся не только к инновациям, 
            но и к созданию прозрачных и честных условий для всех участников. Мы понимаем, что доверие является основой любых отношений, и поэтому наша команда активно работает 
            над повышением уровня прозрачности и безопасности всех наших операций. С помощью нашего опыта и технологий мы хотим сделать стейкинг доступным для всех, независимо от уровня подготовки. 
            Мы уверены, что каждый может стать частью будущего финансов, и мы готовы помочь вам в этом пути.`,
        },
        {
            img: image2,
            title: 'Зачем сотрудничать с нами?',
            text: `<span>Простота использования</span> - ImperiumBroker предлагает интуитивно понятный интерфейс и однокликовую установку для размещения монет, 
            делая процесс стейкинга максимально доступным для всех пользователей. <span>Разнообразие криптовалют</span> - мы предлагаем широкий выбор криптовалют для стейкинга, 
            обеспечивая пользователям возможность диверсификации своего портфеля и выбора наиболее подходящих активов для инвестирования. Компания ImperiumBroker начала свой путь в 2021 году, 
            когда группа крипто энтузиастов под руководством Бенджамина Вебера объединилась, чтобы решить одну из главных проблем в мире криптовалют - доступность стейкинга. Их идея была проста: 
            создать платформу, которая сделает стейкинг простым, надежным и доступным для каждого, независимо от технических или финансовых знаний. Стремясь к этой цели, команда ImperiumBroker начала 
            разработку инновационных решений, которые сделали инвестирование в криптовалюты более привлекательным и доступным.`,
        },
        {
            img: image3,
            title: 'Платформа ImperiumBroker провела страхование ваших инвестиций, предоставив дополнительный уровень безопасности.',
            text: `Наша платформа заключила сделку на страхование активов на общую сумму $140 миллионов. Это событие отмечает новую эру надежности и защиты в мире инвестиций.
            Несмотря на безупречную историю выплат и репутацию надежного исполнения своих обязательств перед нашими инвесторами, мы понимаем, что безопасность инвестиций всегда является важным аспектом в вашем решении. 
            Ведь форс-мажорные события могут случиться вне зависимости от стабильности нашей работы. Именно поэтому мы приняли важное решение - заключить договор на страхование инвестиций на сумму $140 миллионов 
            с транснациональным финансовым конгломератом, который обслуживает более 30 миллионов клиентов по всему миру и имеет безупречную репутацию, собранную за 200 лет работы в сфере страхования.
            Соглашение о страховании гарантирует, что в случае возникновения любых непредвиденных событий, ущерб, понесенный инвесторами в результате неплатежеспособности платформы ImperiumBroker, будет полностью покрыт.`,
        },
    ]

    return(
        <main>
            <section className="about">
                <div className="container">
                    <div className="about-wrapper">
                        <TitleSection
                        title='Стейкинг платформа будущего'
                        subtitle="Мы создаем инновационную блокчейн экосистему, доступную и удобную для каждого пользователя."
                        />
                        <BlockInfo 
                            items={aboutBlockInfo}
                        />
                        <Documents/>
                        
                    </div>
                </div>
            </section>
		</main>
    )
}

export default Company;