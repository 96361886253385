import { useState } from 'react';

import AccountTitle from "../accountTitle/AccountTitle"
import PaymentSystems from "../paymentSystems/PaymentSystems"
import PaymentProcessing from "../paymentProcessing/PaymentProcessing"
import './accountTopUpBalance.scss'


const AccountTopUpBalance = () => {

    const [step, setStep] = useState(1)
    const [amount, setAmount] = useState('')
    const [selectedPaymentSystem, setSelectedPaymentSystem] = useState(null)

    const onAmountChange = (e) => {
        setAmount(e.target.value)
    };

    const onPaymentSystemChange = (paymentSystem) => {
        setSelectedPaymentSystem(paymentSystem)
    };

    const onNextStep = () => {
        setStep(step + 1)
    };

    const onPrevStep = () => {
        setStep(1)
        setAmount('')
        setSelectedPaymentSystem(null)
    };

    return (
        <div className="topup">
            <div className="container">
                <AccountTitle
                    title="Пополнить баланс"
                />

                <div className="topup-wrapper">
                    
                {
                    step === 1 ? (
                        <PaymentSystems
                            onAmountChange={onAmountChange}
                            amount={amount}
                            onPaymentSystemChange={onPaymentSystemChange}
                            onNextStep={onNextStep}
                            isReadyForNextStep={amount && selectedPaymentSystem !== null}
                        />
                    ) : (
                        <PaymentProcessing
                            amount={amount}
                            selectedPaymentSystem={selectedPaymentSystem}
                            onPrevStep={onPrevStep}
                        />
                    )
                }
                </div>
            </div>
        </div>
    )
}

export default AccountTopUpBalance