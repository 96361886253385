import TitleSection from "../components/titleSection/TitleSection"
import './../styles/components/card.scss'
import cardBonusImg from './../resources/images/card/card-bonus.png'
import cardCondImg from './../resources/images/card/card-cond-img.png'
import cardOrderImg from './../resources/images/card/card-preview.png'

const Card = () => {
    return (
        <section className="card">
            <div className="container">
                <TitleSection
                    title='Дебетовая карта'
                    subtitle='Закажите дебетовую карту ImperiumBroker уже сегодня!'
                />
                <div className="card-wrapper">
                    <p className="text">Платформа ImperiumBroker открывает перед вами возможность использовать собственную дебетовую пластиковую карту - это невероятно удобное и эффективное решение для наших инвесторов.<br/><br/>
                    У нас вы можете получить прямой доступ к вашим средствам в системе, что дает вам возможность ускорить процесс и сэкономить на комиссиях, чтобы получить свою прибыль в наличной форме. 
                    Весь этот функционал становится реальностью благодаря нашей собственной дебетовой карте ImperiumBroker. Ее использование значительно расширяет ваши финансовые возможности и экономит ваше время и средства.
                    </p>
                    <div className="card-info">
                        <div className="card-info-item first">
                            <p className="card-info-item-text">Наши дебетовые карты ImperiumBroker работают на международной платежной системе Union Pay</p>
                        </div>
                        <div className="card-info-item second">
                            <p className="card-info-item-text">Обслуживание карт осуществляется крупнейшим британским банком HSBC Bank.</p>
                        </div>
                        <div className="card-info-item third">
                            <p className="card-info-item-text">При использовании нашей дебетовой карты для вас предусмотрен приятный кэшбэк сервис, который поможет вам сэкономить до 30% при покупки или заработать до 5% при инвестировании.</p>
                        </div>
                    </div>
                    <div className="card-bonus">
                        <TitleSection
                        title='Дебетовую карту ImperiumBroker можно получить, имея активный стейкинг в системе на сумму от'
                        />
                        <div className="card-bonus-img">
                            <img src={cardBonusImg} alt="бонус" />
                        </div>
                        <div className="card-bonus-text text">
                            <h3>И, что приятно, стоимость изготовления и доставки карты - 0$.</h3>
                            <p>Срок получения карты составляет от 2 до 5 недель.</p>
                        </div>
                    </div>
                    <div className="conditions">
                        <h3 className="conditions-title gradient-text">Условия обслуживания карты следующие:</h3>
                        <div className="conditions-inner">
                            <div className="conditions-img">
                                <img src={cardCondImg} alt="карта" />
                            </div>
                            <div className="conditions-inner-box">
                                <div className="conditions-row">
                                    <div className="conditions-row-item">
                                        <h4 className="conditions-row-item-title">Месячная абонентская плата</h4>
                                        <p className="conditions-row-item-text text">2 евро.</p>
                                    </div>
                                    <div className="conditions-row-item">
                                        <h4 className="conditions-row-item-title">Комиссия за снятие наличных</h4>
                                        <p className="conditions-row-item-text text">3 евро или 2%.</p>
                                    </div>
                                    <div className="conditions-row-item">
                                        <h4 className="conditions-row-item-title">Проверка баланса в банкомате</h4>
                                        <p className="conditions-row-item-text text">0,25 евро.</p>
                                    </div>
                                </div>
                                <h4 className="conditions-row-title gradient-text">Лимиты пополнения дебетовой карты ImperiumBroker:</h4>
                                <div className="conditions-row">
                                    <div className="conditions-row-item">
                                        <h4 className="conditions-row-item-title">В день</h4>
                                        <p className="conditions-row-item-text text">20 000 евро.</p>
                                    </div>
                                    <div className="conditions-row-item">
                                        <h4 className="conditions-row-item-title">В месяц</h4>
                                        <p className="conditions-row-item-text text">160 000 евро.</p>
                                    </div>
                                    <div className="conditions-row-item">
                                        <h4 className="conditions-row-item-title">В год</h4>
                                        <p className="conditions-row-item-text text">1 500 000 евро.</p>
                                    </div>
                                </div>
                                <h4 className="conditions-row-title gradient-text">Лимиты на снятие наличных (Комиссия 0.5% во всех банкоматах):</h4>
                                <div className="conditions-row">
                                    <div className="conditions-row-item">
                                        <h4 className="conditions-row-item-title">Разовое снятие</h4>
                                        <p className="conditions-row-item-text text">1 500 евро.</p>
                                    </div>
                                    <div className="conditions-row-item">
                                        <h4 className="conditions-row-item-title">Дневной лимит</h4>
                                        <p className="conditions-row-item-text text">6 000 евро.</p>
                                    </div>
                                    <div className="conditions-row-item">
                                        <h4 className="conditions-row-item-title">Лимит наличных в месяц</h4>
                                        <p className="conditions-row-item-text text">14 000 евро.</p>
                                    </div>
                                </div>
                                <h4 className="conditions-row-title gradient-text">Лимиты для интернет-платежей:</h4>
                                <div className="conditions-row">
                                    <div className="conditions-row-item">
                                        <h4 className="conditions-row-item-title">Разовая покупка</h4>
                                        <p className="conditions-row-item-text text">4 000 евро.</p>
                                    </div>
                                    <div className="conditions-row-item">
                                        <h4 className="conditions-row-item-title">Дневной лимит</h4>
                                        <p className="conditions-row-item-text text">8 000 евро.</p>
                                    </div>
                                    <div className="conditions-row-item">
                                        <h4 className="conditions-row-item-title">Лимит в месяц</h4>
                                        <p className="conditions-row-item-text text">50 000 евро.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-order">
                        <h3 className="card-order-title gradient-text">Сделайте ваш доступ к средствам на балансе нашей платформы еще более удобным и глобальным, воспользовавшись дебетовой картой ImperiumBroker</h3>
                        <div className="card-order-inner">
                            <div className="card-order-img">
                                <img src={cardOrderImg} alt="карта" />
                            </div>
                            <a className="profit-instruction-button button" href="https://t.me/ImperiumBroker_manager" target="_blank" rel="noreferrer">Заказать карту</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Card
