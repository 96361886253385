import AccountTitle from "../accountTitle/AccountTitle"
import DealCalendarWidget from "../../widgets/dealCalendarWidget/DealCalendarWidget"
import './accountDealCalendar.scss'

const AccountDealCalendar = () => {
    return(
        <div className="calendar">
            <div className="container">
                <AccountTitle
                    title="Календарь сделок"
                />
                <h3 className="calendar-title">Ознакомьтесь с расписанием ключевых сделок ImperiumBroker</h3>
                <p className="calendar-text">С помощью данного виджета Вы можете удобно следить за предстоящими экономическими событиями, объявлениями и новостями.</p>
            </div>
            <DealCalendarWidget/>
        </div>
    )
}

export default AccountDealCalendar