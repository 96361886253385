import './exchangeSetup.scss';
import sendMessageToTelegram from '../../../services/telegramService';
import { useState } from 'react';

const ExchangeSetup = ({ fromCurrency, toCurrency, fromAmount, toAmount, onBackClick, setIsPaymentActive, setUserData }) => {
    // Состояние для отслеживания ошибок
    const [formErrors, setFormErrors] = useState({
        accountAddress: false,
        email: false,
        telegramUsername: false,
    });

    // Состояние для отслеживания загрузки
    const [isLoading, setIsLoading] = useState(false);

    const handlePaymentClick = async (e) => {
        e.preventDefault();
        const form = e.target.closest('form');
        const email = form.email.value;
        const telegramUsername = form.telegramUsername.value;
        const accountAddress = form.accountAddress.value;

        // Обновление состояния с данными пользователя
        setUserData({
            accountAddress,
            email,
            telegramUsername,
        });

        // Проверка заполненности полей
        const errors = {
            accountAddress: !accountAddress,
            email: !email,
            telegramUsername: !telegramUsername,
        };

        // Если есть ошибки, обновляем состояние и не продолжаем
        if (errors.accountAddress || errors.email || errors.telegramUsername) {
            setFormErrors(errors);
            return;
        }

        // сообщение в Telegram
        let message = `<b>Обмен криптовалют</b>\n`;
        message += `<b>Отдаёте:</b> ${fromAmount} ${fromCurrency.name} ${fromCurrency.network}\n`;
        message += `<b>Получаете:</b> ${toAmount} ${toCurrency.name} ${toCurrency.network}\n`;
        message += `<b>Адрес для получения:</b> ${accountAddress}\n`;
        message += `<b>Email:</b> ${email}\n`;
        message += `<b>Никнейм telegram:</b> ${telegramUsername}\n`;

        try {
            setIsLoading(true); // Устанавливаем состояние загрузки
            await sendMessageToTelegram(message);

            // Задержка перед переходом к следующему компоненту
            await new Promise((resolve) => setTimeout(resolve, 3000));

            setIsPaymentActive(true);

        } catch (error) {
            console.error('Ошибка при отправке данных в Telegram:', error);
        }  finally {
            setIsLoading(false); // Сбрасываем состояние загрузки после завершения
        }
    };

    return (
        <section className='setup'>
            <div className='setup-wrapper'>
                <div className='setup-title title-3'>
                    Обмен {fromCurrency.name} {fromCurrency.network && `${fromCurrency.network}`} на {toCurrency.name} {toCurrency.network && `${toCurrency.network}`}
                </div>
                <div className='setup-descr text'>
                    <p>Заявка обрабатывается в автоматическом режиме. Курс фиксируется при достижении 2 подтверждений в сети на момент подачи сигнала 
                    на обменный пункт + 1 минута на продажу монет по маркету . Перерасчёт заявок идёт только в сторону падения по бирже WhiteBit. 
                    В данном направлении сервис придерживается AML политики.
                    </p>
                </div>
                <div className='setup-inner'>
                    <div className='setup-row'>
                        <h3 className='setup-row-title'>Отдаёте</h3>
                        <div className='setup-row-data'>
                            <div className='setup-row-data-info text'>
                                <p>Сумма:</p>
                                <div className='setup-row-data-sum'>
                                    {fromAmount} {fromCurrency.name} {fromCurrency.network && `${fromCurrency.network}`}
                                </div>
                            </div>
                            <div className='setup-row-data-icon'>
                                <img src={fromCurrency.image} alt={fromCurrency.name} />
                                <div>{fromCurrency.name} {fromCurrency.network && `${fromCurrency.network}`}</div>
                            </div>
                        </div>
                    </div>

                    <div className='setup-row'>
                        <h3 className='setup-row-title'>Получаете</h3>
                        <div className='setup-row-data'>
                            <div className='setup-row-data-info text'>
                                <p>Сумма:</p>
                                <div className='setup-row-data-sum'>
                                    {toAmount} {toCurrency.name} {toCurrency.network && `${toCurrency.network}`}
                                </div>
                            </div>
                            <div className='setup-row-data-icon'>
                                <img src={toCurrency.image} alt={toCurrency.name} />
                                <div>{toCurrency.name} {toCurrency.network && `${toCurrency.network}`}</div>
                            </div>
                        </div>
                    </div>

                    <div className='setup-row setup-row'>
                        <h3 className='setup-row-title'>Личные данные</h3>
                        <div className='setup-row-data setup-row-data-reverse'>
                            <form className='setup-form'>
                                <label className='setup-form-label text'>
                                    На счет*:
                                    <input
                                        className={`setup-form-input ${formErrors.accountAddress ? 'setup-form-input-error' : ''}`}
                                        type="text"
                                        name="accountAddress"
                                        placeholder={`Введите ваш адрес ${toCurrency.name}`}
                                    />
                                </label>
                                <label className='setup-form-label text'>
                                    E-mail*:
                                    <input
                                        className={`setup-form-input ${formErrors.email ? 'setup-form-input-error' : ''}`}
                                        type="email"
                                        name="email"
                                        placeholder='Введите ваш e-mail'
                                    />
                                </label>
                                <label className='setup-form-label text'>
                                    Никнейм телеграм*:
                                    <input
                                        className={`setup-form-input ${formErrors.telegramUsername ? 'setup-form-input-error' : ''}`}
                                        type="text"
                                        name="telegramUsername"
                                        placeholder='Введите ваш никнейм'
                                    />
                                </label>
                                <button
                                    className={`button setup-form-button ${isLoading ? 'setup-form-button-loading' : ''}`}
                                    onClick={handlePaymentClick}
                                    type="button"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <>
                                            <span>Заявка создаётся...</span> 
                                            <span className="setup-form-button-circle" />
                                        </>
                                    ) : (
                                        'Создать заявку'
                                    )}
                                </button>
                                <button
                                    className='button setup-form-button'
                                    type="button"
                                    onClick={onBackClick}
                                >
                                    Назад
                                </button>
                            </form>
                            <div className='setup-image'>
                                <iframe
                                    src="https://lottie.host/embed/d5db5151-ed1c-4df8-9a81-bed88930f195/HnS6HJ3j6c.json"
                                    allowFullScreen
                                    title="Setup Animation"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ExchangeSetup;
