import './profitDiagram.scss'

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import './profitDiagram.scss';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const ProfitDiagram = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    // Базовый список из 5 криптовалют
    // const baseCryptos = ['Solana', 'TRON', 'Dydx', 'Atom', 'BNB', 'USDT'];

    // Изменяем базовый список в зависимости от ширины экрана
    const baseCryptos = screenWidth > 768 ? ['Solana', 'TRON', 'Dydx', 'Atom', 'BNB', 'DOGE'] : ['Solana', 'TRON', 'Dydx', 'Atom', 'BNB'];

    // Полный список криптовалют
    const allCryptos = useMemo(() => [
        'SOL', 'ETH2.0', 'POL', 'DYDX', 'ZETA', 'BAND', 'SAGA', 'NEAR', 'MANTA', 'DYM',
        'OSMO', 'IOST', 'CORE', 'MINA', 'ACA', 'TON', 'ADA', 'IOTX', 'TIA', 'AVAX',
        'SUI', 'DOT', 'APT', 'SEI', 'ATOM', 'LUNC', 'INJ', 'KAVA', 'ROSE', 'CANTO',
        'DOGE', 'TRX', 'USDT', 'USDC'
    ], []);

    // Функция для получения случайной валюты, исключая текущие
    const getRandomCrypto = useCallback((exclude) => {
        const filteredCryptos = allCryptos.filter(crypto => !exclude.includes(crypto));
        const randomIndex = Math.floor(Math.random() * filteredCryptos.length);
        return filteredCryptos[randomIndex];
    }, [allCryptos]);

    // Функция для генерации случайной доходности
    const getRandomPercentage = () => (Math.random() * (16 - 2) + 2).toFixed(2); // От 2% до 16%

    // Генерация случайных данных доходности для начальных валют
    const [chartData, setChartData] = useState({
        cryptos: baseCryptos,
        percentages: baseCryptos.map(() => getRandomPercentage())
    });

    // Функция для обновления одного столбика
    const updateOneCrypto = useCallback(() => {
        const newCryptos = [...chartData.cryptos];
        const randomIndex = Math.floor(Math.random() * newCryptos.length);
        const newCrypto = getRandomCrypto(newCryptos);

        newCryptos[randomIndex] = newCrypto;
        const newPercentages = [...chartData.percentages];
        newPercentages[randomIndex] = getRandomPercentage();

        setChartData({
            cryptos: newCryptos,
            percentages: newPercentages
        });
    }, [chartData.cryptos, chartData.percentages, getRandomCrypto]);

    // Обновление данных каждые 5 секунд
    useEffect(() => {
        const interval = setInterval(updateOneCrypto, 5000);
        return () => clearInterval(interval);
    }, [updateOneCrypto]);

    // Данные для графика
    const data = {
        labels: chartData.cryptos,
        datasets: [{
            label: 'Доходность (%)',
            data: chartData.percentages,
            backgroundColor: ['#FF6384', '#2C90F7', '#F2994A', '#4BC0C0', '#9966FF', '#4CAF50'],
            borderColor: ['#E55374', '#1E7CD9', '#D98B3F', '#3A9C9C', '#7C50CC', '#4CAF50'],
            borderWidth: 1
        }]
    };

       // Обработчик изменения ширины экрана
       const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Опции для графика
    const options = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                max: 20
            },
            x: {
                ticks: {
                    color: '#fff',
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            }
        },
        animation: {
            duration: 1000,
            easing: 'easeInOutQuart'
        }
    };

    return (
        <div className='profit-diagram'>
            <div className='profit-diagram-descr'>
                <h3 className='title-3'>Доходность криптовалют в реальном времени</h3>
                <p className='text'>
                    На данной диаграмме представлена динамическая информация о доходности популярных криптовалют в режиме реального времени. 
                    График отображает основные активы, таких как Solana, TRON, Dydx, Atom, BNB, DOGE, TRX, USDT и другие, демонстрируя их текущую доходность в процентах. 
                    С помощью данной диаграммы вы сможете лучше понимать, какие активы приносят наибольшую прибыль и как компания ImperiumBroker диверсифицирует активы для получения максимальной прибыли.
                </p>
            </div>
            <div className='profit-diagram-stat'>
                <Bar data={data} options={options} />
            </div>
        </div>
    );
}

export default ProfitDiagram;