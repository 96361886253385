// import AccountTitle from "../accountTitle/AccountTitle"
// import './accountPartners.scss'

// const AccountPartners = () => {
//     return (
//         <div className="partners">
//             <div className="container">
//                 <AccountTitle 
//                     title="Мои партнёры"
//                 />
//                 <div className="partners-list">
                    
//                 </div>
//                 <div className="partners-empty-message text">
//                     <p>У вас пока нет активных партнёров. Здесь будет отображаться список только тех партнёров, которые открыли счёт в компании ImperiumBroker. Для того, что б зарегистрироваться пригласителем обратитесь 
//                     в <a className="link-decor" href="https://t.me/ImperiumBroker_manager" target="_blank" rel="noreferrer">техподдержку</a> для получения реферальной ссылки.
//                     </p>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default AccountPartners
import AccountTitle from "../accountTitle/AccountTitle";
import { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from "firebase/firestore";
import { auth, db } from "../../../services/firebaseConfig";

import './accountPartners.scss';

const AccountPartners = () => {
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        const fetchPartners = async () => {
            if (auth.currentUser) {
                const q = query(
                    collection(db, "users"), 
                    where("referrerId", "==", auth.currentUser.uid)
                );
                const querySnapshot = await getDocs(q);
                const partnersList = querySnapshot.docs.map(doc => doc.data());
                setPartners(partnersList);
            }
        };

        fetchPartners();
    }, []);

    return (
        <div className="partners">
            <div className="container">
                <div className="partners-top">
                    <AccountTitle title="Мои партнёры" />
                    <span className="partners-top-count">({partners.length})</span> 
                </div>
                {partners.length > 0 ? (
                    <div className="partners-list">
                        {partners.map((partner, index) => (
                            <div key={index} className="partners-item text">
                                <p>Никнейм: {partner.email}</p>
                                <p>Дата регистрации: {partner.registrationDate.toDate().toLocaleDateString()}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="partners-empty-message text">
                        <p>У вас пока нет активных партнёров. Здесь будет отображаться список тех партнёров, которые прошли регистрацию по вашей реферальной ссылке</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AccountPartners;
