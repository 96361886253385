import './documents.scss';
import TitleSection from '../titleSection/TitleSection';
import doc1 from './../../resources/images/documents/chesterfield_group.png'
import doc2 from './../../resources/images/documents/new_zealand_government.png'

const Documents = () => {
    return (
    <div className="documents">
        <TitleSection 
        title='Документы'
        subtitle='Компания ImperiumBroker сертифицирована на предоставление безопасных и прозрачных финансовых услуг с соблюдением законодательства Новой Зеландии. Мы гарантируем полное соответствие требованиям регуляторов, обеспечивая надежность и законность всех операций. Ниже представлены документ страхования и сертификат компании.'   
        />
        <div className="documents-list">
            <a className="documents-item" href={doc1} target="_blank" rel="noopener noreferrer">
                <img className="documents-img" src={doc1} alt="Документ 1"/>
            </a>
            <a className="documents-item" href={doc2} target="_blank" rel="noopener noreferrer">
                <img className="documents-img" src={doc2} alt="Документ 2"/>
            </a>
        </div>
    </div>
    )

}

export default Documents;