import './blockInfo.scss';

const BlockInfo = ( {items} ) => {
    return (
        <div className="block-info">
            {
                items.map((item, index) => {
                    return(
                        <div key={index} className="block-info-row" data-aos="zoom-in-down" data-aos-duration="1500">
                            <div className="block-info-image">
                                <img src={item.img} alt="block-info"/>
                            </div>
                            <div className="block-info-descr">
                                <h3 className="block-info-descr-title title-3">{item.title}</h3>
                                <p className="block-info-descr-text text" dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                <div className="block-info-descr-link" dangerouslySetInnerHTML={{ __html: item.link }}></div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default BlockInfo;
