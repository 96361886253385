import TitleSection from "../components/titleSection/TitleSection"
import BlockHow from "../components/blockHow/BlockHow"
import './../styles/components/cashback.scss'

import cashbackTopImg from './../resources/images/cashback/cashback-img.png'
import cashbackPerImg from './../resources/images/cashback/cashback-percent.png'

import howIcon1 from './../resources/images/profit/how-icon1.svg';
import howIcon2 from './../resources/images/profit/how-icon2.svg';
import howIcon3 from './../resources/images/profit/how-icon3.svg';

const Cashback = () => {

    const cachbachHow = [
        {
            icon: howIcon1,
            title: `Получите карту ImperiumBroker`,
            text: `Получите нашу карту выполнив все необходимые условия.`,
        },
        {
            icon: howIcon2,
            title: 'Совершайте покупки',
            text: `Используйте карту ImperiumBroker для оплаты товаров и услуг в любых магазинах и онлайн.`,
        },
        {
            icon: howIcon3,
            title: 'Получайте Cashback',
            text: `Каждую транзакцию с картой ImperiumBroker награждается Cashback. Средства автоматически возвращаются на ваш счет и доступны для использования.`,
        }
      ];



    return (
        <section className="cashback">
            <div className="container">
                <TitleSection
                    title='Программа кэшбэка от ImperiumBroker'
                    subtitle='Возврат средств, который вас удивит!'
                />
                <div className="cashback-wrapper">
                    <div className="cashback-top">
                        <div className="cashback-top-descr">
                            <h3 className="cashback-top-descr-title gradient-text">Добро пожаловать в увлекательный мир эксклюзивных привилегий и щедрых вознаграждений с программой Cashback от ImperiumBroker!</h3>
                            <p className="text">Мы гордимся представить вам нашу уникальную карту, которая не только облегчит вашу финансовую жизнь, но и подарит вам средства за каждую покупку.</p>
                            <h3 className="cashback-top-descr-title gradient-text">Что такое кэшбэк</h3>
                            <ul className="text cashback-top-descr-list">
                                <li className="cashback-top-descr-item">Это возврат части средств, потраченных при оплате покупок с использованием банковской карты. Если оплата была произведена с долларового счета, то кэшбэк будет начислен в долларах, а в случае оплаты с евро счета - в евро.</li>
                                <li className="cashback-top-descr-item">Средства, полученные в виде кэшбэка, можно использовать по вашему усмотрению в любое удобное время. Условия программы лояльности могут варьироваться.</li>
                            </ul>
                        </div>
                        <div className="cashback-top-img">
                            <img src={cashbackTopImg} alt="card" />
                        </div>
                    </div>

                    <BlockHow items={cachbachHow}/>

                    <div className="cashback-advantages">
                        <TitleSection
                        title='Почему выбрать Cashback карту ImperiumBroker?'
                        />
                        <div className="advantages-inner">
                            <div className="advantages-row">
                                <h4 className="advantages-row-title">Зарабатывайте до 30% с каждой покупки</h4>
                                <p className="advantages-row-text text">С каждой траты средств с вашей карты ImperiumBroker вы будете получать процент от суммы назад на ваш счет. Это просто и выгодно!</p>
                            </div>
                            <div className="advantages-row">
                                <h4 className="advantages-row-title">Разнообразие партнеров</h4>
                                <p className="advantages-row-text text">Наша сеть партнеров постоянно расширяется. Покупайте ваши любимые товары и услуги, и получайте Cashback в различных отраслях.</p>
                            </div>
                            <div className="advantages-row">
                                <h4 className="advantages-row-title">Бонусы и акции</h4>
                                <p className="advantages-row-text text">Мы регулярно устраиваем специальные акции и предлагаем дополнительные бонусы для наших участников</p>
                            </div>
                            <div className="advantages-row">
                                <h4 className="advantages-row-title">Легкость использования</h4>
                                <p className="advantages-row-text text">Карта ImperiumBroker легко интегрируется в вашу финансовую повседневность. Оплачивайте ею онлайн или оффлайн, как вам удобно.</p>
                            </div>
                            <div className="advantages-row">
                                <h4 className="advantages-row-title">Безопасность и защита</h4>
                                <p className="advantages-row-text text">Использование карты ImperiumBroker обеспечивает высокий уровень безопасности транзакций и защиту ваших средств. Вы можете быть уверены в том, что ваши финансы находятся под надежной защитой.</p>
                            </div>
                        </div>
                    </div>
                    <div className="cashback-types">
                        <div className="types">
                            <div className="types-item">
                                <h4 className="types-item-title">До 15% кэшбэка</h4>
                                <p className="types-item-text text">Предоставляется за покупки в ресторанах, покупку билетов и использование услуг. За ужин в ресторане можно вернуть до 10% от стоимости, а за билеты в кино, театр и на концерты - 5%.
                                Кроме того, за другие покупки и услуги предоставляется кэшбэк в размере от 5% до 15%. Кэшбэк за билеты может достигать 15%. Закажите карту для получения дополнительных преимуществ.
                                </p>
                            </div>
                            <div className="types-item">
                                <img src={cashbackPerImg} alt="карта процентов" />
                            </div>
                            <div className="types-item">
                                <h4 className="types-item-title">До 30% кэшбэка</h4>
                                <p className="types-item-text text">Доступно за покупки у партнеров, которые включают популярные магазины, сервисы и кафе. Программа предоставляет уникальные предложения от партнеров, и клиенты могут получать значительный возврат средств. 
                                Cashback ImperiumBroker - это способ сделать ваши покупки еще более выгодными и зарабатывать при этом.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Cashback

















// import { useEffect, useState } from 'react';
// import TitleSection from '../components/titleSection/TitleSection';
// // import './../styles/components/exchange.scss';
// import './../styles/components/cashback.scss'

// const Exchange = () => {
//     const [currencyFrom, setCurrencyFrom] = useState('');
//     const [currencyTo, setCurrencyTo] = useState('');
//     const [amount, setAmount] = useState('');
//     const [currencies, setCurrencies] = useState([]);

//     useEffect(() => {
//         // Запрос к API CoinGecko для получения списка криптовалют
//         fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false')
//             .then(response => response.json())
//             .then(data => {
//                 setCurrencies(data); // Сохраняем список валют
//             })
//             .catch(error => console.error('Ошибка при загрузке валют:', error));
//     }, []);

//     const handleExchange = () => {
//         if (currencyFrom && currencyTo && amount) {
//             alert(`Обмен ${amount} ${currencyFrom} на ${currencyTo}`);
//         } else {
//             alert('Пожалуйста, заполните все поля!');
//         }
//     };

//     return (
//         <section className="exchange">
//             <div className="container">
//                 <TitleSection
//                     title="Обмен валют"
//                     subtitle="Выберите валюты и введите сумму для обмена"
//                 />
//                 <div className="exchange-form">
//                     <div className="form-group">
//                         <label>Из валюты:</label>
//                         <select value={currencyFrom} onChange={(e) => setCurrencyFrom(e.target.value)}>
//                             <option value="">Выберите валюту</option>
//                             {currencies.map(currency => (
//                                 <option key={currency.id} value={currency.symbol}>
//                                     {currency.name} ({currency.symbol.toUpperCase()})
//                                 </option>
//                             ))}
//                         </select>
//                     </div>

//                     <div className="form-group">
//                         <label>В валюту:</label>
//                         <select value={currencyTo} onChange={(e) => setCurrencyTo(e.target.value)}>
//                             <option value="">Выберите валюту</option>
//                             {currencies.map(currency => (
//                                 <option key={currency.id} value={currency.symbol}>
//                                     {currency.name} ({currency.symbol.toUpperCase()})
//                                 </option>
//                             ))}
//                         </select>
//                     </div>

//                     <div className="form-group">
//                         <label>Сумма:</label>
//                         <input
//                             type="number"
//                             value={amount}
//                             onChange={(e) => setAmount(e.target.value)}
//                             placeholder="Введите сумму"
//                         />
//                     </div>

//                     <button className="exchange-button" onClick={handleExchange}>
//                         Обмен
//                     </button>
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default Exchange;
