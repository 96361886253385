import Hero from "../components/hero/Hero";
import ProfitSection from "../components/profitSection/ProfitSection";
import TradingSection from "../components/tradingSection/TradingSection";
import AdvantagesSection from "../components/advantagesSection/AdvantagesSection";

const Home = () => {
    return(
        <>
            <Hero/>
            <TradingSection/>
            <ProfitSection/>
            <AdvantagesSection/>
        </>
    )
}

export default Home;