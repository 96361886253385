import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "./lastTradesChart.scss";

// Список криптовалют
const cryptoCurrencies = 
[ 
    "BTC", "ETH", "USDT", "BNB", "SOL",
    "USDC", "XRP", "DOGE", "TRX", "TON",
    "ADA", "WBTC", "AVAX", "SHIB", "LINK",
    "BCH", "DOT", "NEAR", "LEO", "SUI",
    "DAI", "LTC", "APT", "UNI", "PEPE",
    "TAO", "ICP", "FET", "KAS", "XMR",
    "XLM", "ETC", "POL", "STX", "WBT",
    "RENDER", "FDUSD", "WIF", "IMX", "OKB",
    "ARB", "AAVE", "FIL", "OP", "CRO",
    "MNT", "INJ", "FTM", "HBAR", "VET"
];

// Функция для генерации случайных данных обмена
const getRandomTrade = () => {
  let crypto1, crypto2;
  do {
    crypto1 = cryptoCurrencies[Math.floor(Math.random() * cryptoCurrencies.length)];
    crypto2 = cryptoCurrencies[Math.floor(Math.random() * cryptoCurrencies.length)];
  } while (crypto1 === crypto2);

  const amount = (Math.random() * (3000 - 10) + 10).toFixed(2);

  return {
    pair: `${crypto1} / ${crypto2}`,
    amount: parseFloat(amount)
  };
};

const LastTradesChart = () => {
  const [tradeData, setTradeData] = useState({
    labels: [],
    datasets: [
      {
        label: "Объём обмена (в $)",
        data: [],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  });

  const [lastTrade, setLastTrade] = useState({ pair: "", amount: 0 });

  useEffect(() => {
    const getInitialTradesCount = () => {
      return window.innerWidth < 768 ? 5 : 7; // Проверяем ширину экрана
    };

    const initialTrades = Array.from({ length: getInitialTradesCount() }, getRandomTrade);
    
    // Установка начальных данных для графика
    setTradeData((prevData) => ({
      labels: initialTrades.map(trade => trade.pair),
      datasets: prevData.datasets.map((dataset) => ({
        ...dataset,
        data: initialTrades.map(trade => trade.amount),
      })),
    }));

    // Установка последнего обмена из начальных данных
    setLastTrade(initialTrades[initialTrades.length - 1]);

    const updateTrade = () => {
      const newTrade = getRandomTrade();
      setLastTrade(newTrade);

      setTradeData((prevData) => {
        const updatedLabels = [...prevData.labels.slice(1), newTrade.pair];
        const updatedData = [...prevData.datasets[0].data.slice(1), newTrade.amount];

        return {
          labels: updatedLabels,
          datasets: prevData.datasets.map((dataset) => ({
            ...dataset,
            data: updatedData,
          })),
        };
      });
      
      // Случайная задержка от 10 до 20 секунд
      const randomDelay = Math.floor(Math.random() * 11000) + 10000; // от 10000 до 21000 мс
      setTimeout(updateTrade, randomDelay);
    };

    // Начальное обновление через случайный интервал
    const initialDelay = Math.floor(Math.random() * 11000) + 10000; // от 10000 до 21000 мс
    const initialTimeout = setTimeout(updateTrade, initialDelay);

    return () => clearTimeout(initialTimeout);
  }, []);

  return (
    <div className="last-trades">
        <div className="last-trades-top">
            <h2 className="title-3">Статистика обменов в режиме реального времени</h2>
            <p className="text">Не упустите возможность быть в курсе последних обменов на нашей платформе. Меняйте криптовалюту на любую из доступных с минимальными затратами времени.</p>
        </div>
        <div className="last-trades-pair">
            <h2>Последний обмен: <span>{lastTrade.pair} — ${lastTrade.amount}</span></h2>
        </div>
        <div className="last-trades-diagram">
            <Bar data={tradeData} options={{
            maintainAspectRatio: false,
            scales: {
                x: {
                title: {
                    display: true,
                    text: 'Валютные пары',
                },
                },
                y: {
                title: {
                    display: true,
                    text: 'Объём обмена ($)',
                },
                beginAtZero: true,
                },
            },
            }} />
        </div>
    </div>
  );
};

export default LastTradesChart;




