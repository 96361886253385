import './profitSection.scss'
import BlockInfo from "../blockInfo/BlockInfo"
import BlockHow from "../blockHow/BlockHow"
import TitleSection from '../titleSection/TitleSection'
import CryptoAssetsChart from '../diagrams/cryptoAssetsChart/CryptoAssetsChart'
import btc2block from './../../resources/images/profit/btc-2-block.svg'
import hand2block from './../../resources/images/profit/hand-2-block.svg'
import hand2blockr from './../../resources/images/profit/hand-2-block-r.svg'

import image1 from './../../resources/images/profit/image1.png'
import image2 from './../../resources/images/profit/image2.png'

import howIcon1 from './../../resources/images/profit/how-icon1.svg'
import howIcon2 from './../../resources/images/profit/how-icon2.svg'
import howIcon3 from './../../resources/images/profit/how-icon3.svg'


const ProfitSection = () => {

    const profitBlockInfo = [
        {
            img: image1,
            title: 'Отличие стейкинга от майнинга',
            text: `Сейчас обычные компьютеры уже не могут поддерживать нужный уровень вычислительной мощности для получения блоков сети bitcoin. 
            Блокчейны, работающие на основе алгоритма Proof of Stake (PoS), избавляют участников сети от подобных сложностей. 
            Все, что нужно для получения пассивного дохода, – это держать монеты на специальной платформе и не использовать их. 
            Количество активов на счету можно накапливать, повышая тем самым шансы на добычу блока. Такой процесс не требует подключения мощного оборудования, 
            что делает порог входа в стейкинг намного ниже, чем в майнинг. Плюс некоторые монеты стоят недорого, и зарабатывать на их хранении могут люди с небольшим капиталом.`
        },
        {
            img: image2,
            title: 'Автоматическая диверсификация ваших активов',
            text: `ImperiumBroker использует все доступные виды стейкинга — фиксированный, бессрочный и DeFi. 
            Наша система автоматически распределяет ваши средства между этими направлениями, обеспечивая оптимальный баланс доходности и гибкости. 
            Вам не нужно разбираться в тонкостях — алгоритмы анализируют текущую рыночную ситуацию и выбирают наиболее выгодные условия для инвестиций. 
            Это позволяет диверсифицировать активы и снизить риски, одновременно увеличивая потенциал прибыли. Мы делаем стейкинг простым и эффективным, 
            чтобы ваши криптовалюты всегда приносили максимальный доход без лишних усилий.`,
        },
      ];

    const profitHow = [
        {
            icon: howIcon1,
            title: `Фиксированный стейкинг (Locked Staking)`,
            text: `При таком подходе от пользователя требуется заранее указать, на какой период он размещает активы на счету. 
            Владелец токенов имеет возможность выбрать удобный срок, но потом он не должен будет его изменять.`,
            class: 'trading-item'
        },
        {
            icon: howIcon2,
            title: 'Бессрочный стейкинг (Flexible Staking)',
            text: `При таком размещении монет не указывается конечная дата периода их держания. Пользователь может прекратить участие в процессе валидации, когда посчитает нужным. 
            Проценты будут начисляться до тех пор, пока участник сети не выведет свои токены`,
            class: 'trading-item'
        },
        {
            icon: howIcon3,
            title: 'DeFi-стейкинг',
            text: `В основе работы DeFi-проектов лежит использование умных контрактов. Они обеспечивают автоматическое исполнение сделок с соблюдением заранее 
            установленных условий. В течение суток можно сразу забрать свои проценты, которые начисляются каждый день.`,
            class: 'trading-item'
        }
      ];

    return(
        <section className="profit" id="profit">
            <div className="profit-bg">
                <img className="profit-bg-1" src={btc2block} alt="profit-bg-1"/>
                <img className="profit-bg-2" src={hand2block} alt="profit-bg-2"/>
                <img className="profit-bg-3" src={hand2blockr} alt="profit-bg-3"/>
            </div>
            <div className="container">
                <div className="profit-wrapper">
                
                    <TitleSection
                        title='Эволюция крипто-заработка: Стейкинг против Майнинга'
                        subtitle="Доступный доход без оборудования и сложностей, с автоматической диверсификацией активов"
                    />

                    <BlockInfo items={profitBlockInfo}/>

                    <BlockHow items={profitHow}/>
                    <CryptoAssetsChart/>
                </div>
            </div>
        </section>
    )
}

export default ProfitSection;