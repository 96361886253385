import TitleSection from '../components/titleSection/TitleSection'
import './../styles/components/guide.scss'

import regImg1 from './../resources/images/guide/registration.png'
import regImg2 from './../resources/images/guide/registration2.png'
import topupImg1 from './../resources/images/guide/topup1.png'
import topupImg2 from './../resources/images/guide/topup2.png'
import topupImg3 from './../resources/images/guide/topup3.png'
import topupImg4 from './../resources/images/guide/topup4.png'
import withdrawImg from './../resources/images/guide/withdraw.png'

const Guide = () => {

    return (
        <section className="guide">
            <div className='container'>
                <TitleSection
                    title='Пошаговая инструкция'
                    subtitle='Узнайте, как легко и быстро начать зарабатывать на стейкинге с пошаговыми инструкциями!'
                />
                <div className='guide-wrapper'>
                    <div className='guide-descr'>
                        <h3 className='title-3 guide-descr-title'>Как это работает?</h3>
                        <p className='guide-descr-text text'>
                        Этот текст поможет вам разобраться с порядком основных действий, без которых сотрудничество и инвестирование с компанией ImperiumBroker не будет полноценным.<br/><br/>
                        Наши эксперты подготовили пошаговые инструкции. Простой и понятный образовательный материал подойдет вам, даже если вы никогда не были знакомы с инвестиционными онлайн-платформами.<br/><br/>
                        Разберется каждый, ведь информация тщательно продумана и представлена с помощью визуализированных образов.<br/><br/>
                        Вы найдете ответы на все основные вопросы.
                        </p>
                    </div>
                    <div className='guide-list'>
                        <div className='guide-item'>
                            <h2 className='guide-title title-3'>Зарегистрироваться</h2>
                            <div className='guide-step'>
                                <div className='guide-step-descr'>
                                    <h4 className='guide-step-subtitle'>Шаг 1</h4>
                                    <h3 className='guide-step-title'>Начните регистрацию</h3>
                                    <p className='guide-step-text text '>
                                    Как присоединиться к платформе ImperiumBroker? Перейдите на главную страницу сайта. В правом верхнем углу кнопка “Войти”. Она открывает окно регистрации.
                                    </p>
                                </div>
                                <div className='guide-step-img'>
                                    <img src={regImg1} alt="регистрация" />
                                </div>
                            </div>
                            <div className='guide-step'>
                                <div className='guide-step-descr'>
                                    <h4 className='guide-step-subtitle'>Шаг 2</h4>
                                    <h3 className='guide-step-title'>Заполните все поля</h3>
                                    <p className='guide-step-text text'>
                                    Укажите Ваш Email (он будет служить вашим логином). Придумайте надёжный пароль и подтвердите его. Нажмите кнопку "ЗАРЕГИСТРИРОВАТЬСЯ".
                                    </p>
                                </div>
                                <div className='guide-step-img'>
                                    <img src={regImg2} alt="регистрация2" />
                                </div>
                            </div>
                        </div>
                        <div className='guide-item'>
                            <h2 className='guide-title title-3'>Создать депозит</h2>
                            <div className='guide-step'>
                                <div className='guide-step-descr'>
                                    <h4 className='guide-step-subtitle'>Шаг 1</h4>
                                    <h3 className='guide-step-title'>Ввод суммы</h3>
                                    <p className='guide-step-text text'>
                                    Нажмите в Личном Кабинете "ПОПОЛНИТЬ БАЛАНС" и введите сумму, на которую вы желаете открыть депозит.
                                    </p>
                                </div>
                                <div className='guide-step-img'>
                                    <img src={topupImg1} alt="topupImg1" />
                                </div>
                            </div>
                            <div className='guide-step'>
                                <div className='guide-step-descr'>
                                    <h4 className='guide-step-subtitle'>Шаг 2</h4>
                                    <h3 className='guide-step-title'>Выбор платёжной системы</h3>
                                    <p className='guide-step-text text'>
                                    Выберите (просто клик на нужную иконку) удобный для Вас способ отправки средств: криптовалюта или электронные кошельки и нажмите кнопку "ПРОДОЛЖИТЬ".
                                    </p>
                                </div>
                                <div className='guide-step-img'>
                                    <img src={topupImg2} alt="topupImg2" />
                                </div>
                            </div>
                            <div className='guide-step'>
                                <div className='guide-step-descr'>
                                    <h4 className='guide-step-subtitle'>Шаг 3</h4>
                                    <h3 className='guide-step-title'>Ввод суммы</h3>
                                    <p className='guide-step-text text'>
                                    Скопируйте реквизиты компании ImperiumBroker и выполните перевод.
                                    </p>
                                </div>
                                <div className='guide-step-img'>
                                    <img src={topupImg3} alt="topupImg3" />
                                </div>
                            </div>
                            <div className='guide-step'>
                                <div className='guide-step-descr'>
                                    <h4 className='guide-step-subtitle'>Шаг 4</h4>
                                    <h3 className='guide-step-title'>Заполнение формы</h3>
                                    <p className='guide-step-text text'>
                                    ПОСЛЕ оправки средств заполните форму обратной связи и укажите ФИО, Никнейм Telegram. Затем нажмите кнопку "ПОПОЛНИТЬ БАЛАНС".<br/>
                                    * После того, как средства поступят на наш счёт, Ваш депозит будет открыт АВТОМАТИЧЕСКИ, а наш менеджер свяжется с Вами по указанным Вами контактам.
                                    </p>
                                </div>
                                <div className='guide-step-img'>
                                    <img src={topupImg4} alt="topupImg4" />
                                </div>
                            </div>
                        </div>
                        <div className='guide-item'>
                            <h2 className='guide-title title-3'>Вывести средства</h2>
                            <div className='guide-step'>
                                <div className='guide-step-descr'>
                                    <h4 className='guide-step-subtitle'>Шаг 1</h4>
                                    <h3 className='guide-step-title'>Сформируйте заказ</h3>
                                    <p className='guide-step-text text'>
                                    В меню Личного Кабинета нажмите кнопку "ВЫВОД ПРИБЫЛИ". В форме заказа введите необходимую сумму, выберите платежную систему, куда вам поступят средства и укажите ваши реквизиты. Затем нажмите кнопку "ЗАКАЗАТЬ".
                                    </p>
                                </div>
                                <div className='guide-step-img'>
                                    <img src={withdrawImg} alt="вывод" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Guide