import { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './accountCircularProgressbar.scss'
import percentageImage from './../../../resources/images/icons/percentage.png'

const AccountCircularProgressbar = ({ text }) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setValue((prevValue) => {
                if (prevValue >= 100) {
                    return 0;
                }
                    return prevValue + 1;
                });
        }, 100);
  
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <CircularProgressbar
                value={value}
                text={text}
                styles={buildStyles({
                    // цвет кругового индикатора прогресса
                    pathColor: '#2C90F7', 
                    // цвет фонового круга
                    trailColor: '#eeeeeeb3', 
                    // цвет текста
                    textColor: '#73fff2cc',
                    textSize: '14px',
                })}
            />
            <div 
                className="percentage"
            >
                <img 
                    className="percentage-image"
                    src={percentageImage} 
                    alt="percentage" 
                />
            </div>
        </div>
    );
};

export default AccountCircularProgressbar;