import { useState, useEffect } from "react"
import TitleSection from "../components/titleSection/TitleSection"

const News = () => {
    
  const [news, setNews] = useState([]);
  const [displayedNews, setDisplayedNews] = useState(4);

  useEffect(() => {
    fetch("/json/news.json")
      .then((response) => response.json())
      .then((data) => setNews(data));
  }, []);

  const loadMoreNews = () => {
    setDisplayedNews((prevState) => prevState + 4);
  };

  return (
    <main>
        <section className="news">
            <div className="container">
                <div className="news-wrapper">
                    <TitleSection
                        title='Новости'
                        subtitle="Следите за&nbsp;актуальными новостями трейдинга, криптовалют и&nbsp;наших личных достижений!"
                    />
                    <div className="news-list">

                        {news.slice(0, displayedNews).map((item) => {
                            return (
                            <article className="news-item" key={item.id}>
                                <div className="news-item-image">
                                <img src={item.src} alt={item.title} />
                                </div>
                                <div className="news-item-descr">
                                <h3 className="news-item-title title-3">{item.title}</h3>
                                <p className="news-item-text">{item.text}</p>
                                </div>
                            </article>
                            );
                        })}

                    </div>
                    <div className="news-bottom">
                        {
                        displayedNews < news.length ? (
                        <button 
                            className="news-button button" 
                            onClick={loadMoreNews}>
                            Показать еще
                        </button>
                        ) : (
                        <button 
                            className="news-button button" 
                            disabled>
                            Больше нет новостей
                        </button>
                        )
                        }
                    </div>
                </div>
            </div>
        </section>
    </main>
  );
};

export default News;