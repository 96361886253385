import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import "./cryptoAssetsChart.scss"

ChartJS.register(ArcElement, Tooltip, Legend);

const CryptoAssetsChart = () => {
    // Базовый список из 5 валют
    const baseCryptos = ['Solana', 'TRON', 'Dydx', 'Atom', 'BNB'];

    const allCryptos = useMemo(() => [
        'Ethereum', 'Tether', 'XRP', 'Dogecoin', 'Band', 'Iotx', 'Dot', 'WETH', 'Sui', 'Monero', 'Mantle', 'THORChain', 'Celestia', 'MANTRA'
    ], []);

    const getRandomCrypto = useCallback((exclude) => {
        const cryptosCopy = [...allCryptos];
        const randomIndex = Math.floor(Math.random() * cryptosCopy.length);
        const randomCrypto = cryptosCopy[randomIndex];
    
        if (exclude.includes(randomCrypto)) {
            return getRandomCrypto(exclude);
        }
    
        return randomCrypto;
    }, [allCryptos]);

    const getRandomPercentages = (count) => {
        let percentages = [];
        let total = 100;

        for (let i = 0; i < count - 1; i++) {
            const randomPercent = Math.floor(Math.random() * (total / 2));
            percentages.push(randomPercent);
            total -= randomPercent;
        }
        percentages.push(total);

        return percentages;
    };

    const [chartData, setChartData] = useState({
        cryptos: baseCryptos,
        percentages: getRandomPercentages(5)
    });

    const updateOneCrypto = useCallback(() => {
        const newCryptos = [...chartData.cryptos];
        const randomIndex = Math.floor(Math.random() * newCryptos.length);
        const newCrypto = getRandomCrypto(newCryptos);

        newCryptos[randomIndex] = newCrypto;
        setChartData({
            cryptos: newCryptos,
            percentages: getRandomPercentages(5)
        });
    }, [chartData.cryptos, getRandomCrypto]);

    useEffect(() => {
        const interval = setInterval(updateOneCrypto, 5000);
        return () => clearInterval(interval);
    }, [updateOneCrypto]);

    const data = {
        labels: chartData.cryptos.map((crypto, index) => `${crypto} (${chartData.percentages[index]}%)`),
        datasets: [
            {
                label: 'Доля актива',
                data: chartData.percentages,
                backgroundColor: ['#FF6384', '#2C90F7', '#F2994A', '#4BC0C0', '#9966FF'],
                hoverBackgroundColor: ['#E04A5F', '#2167B7', '#D98227', '#37908D', '#7246D3'],
                borderWidth: 1
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    boxWidth: 20,
                    padding: 15,
                    font: {
                        size: 14,
                    }
                }
            },
            tooltip: {
                enabled: true,
            }
        },
        animation: {
            animateRotate: true,
            animateScale: true,
        },
    };

    return (
        <div className='assets-diagram'>
            <div className='assets-diagram-inner'>
                <div className='assets-diagram-descr'>
                    <h3 className='assets-diagram-title title-3'>Распределение стейкинговых активов в режиме реального времени</h3>
                    <p className='text'>
                    На графике представлено распределение стейкинговых активов на нашей платформе в режиме реального времени. Каждый сегмент диаграммы соответствует определённой криптовалюте, участвующей в стейкинге, а рядом с её названием указывается процент активов, вложенных в неё. Динамика распределения обновляется каждые несколько секунд, демонстрируя актуальные данные о том, как распределяются средства инвесторов между популярными криптовалютами, такими как Solana, TRON, BNB и другими. Это помогает оценить текущие тренды и активность в стейкинга.
                    </p>
                </div>
                <div className='assets-diagram-stat'>
                    <Doughnut data={data} options={options} />
                </div>
            </div>
        </div>
    );
};

export default CryptoAssetsChart;






