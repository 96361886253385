import { useState, useEffect, useContext } from 'react'
import { db } from '../../../services/firebaseConfig'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import AuthContext from '../../../context/AuthContext'
import AccountCircularProgressbar from '../accountCircularProgressbar/AccountCircularProgressbar'
import './accountDeposit.scss'
import AccountTitle from '../accountTitle/AccountTitle'
import DepositSecure from '../depositSecure/DepositSecure'
import earth from './../../../resources/images/trading/Earth-3-block-r.svg'


const AccountDeposit = () => {
    const { email } = useContext(AuthContext);
    const [accountBalance, setAccountBalance] = useState(0);

// получение данных пользователя из Firestore и установка значения accountBalance.
    useEffect(() => {
        if (email) {
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'users'),
                    where('email', '==', email)
                ),
                (querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        setAccountBalance(doc.data().accountBalance);
                    });
                },
                (error) => {
                    console.error('Error fetching user data:', error);
                }
            );
    
            return () => {
                unsubscribe();
            };
        }
    }, [email]);

    const calculatePercentage = (balance) => {
        const maxBalance = 1000;
        return (balance / maxBalance) * 100;
      };

    return (
        <div className="deposit">
            <div className="container">
                <AccountTitle 
                        title="Мой счёт"
                    />
                <div className="deposit-body">
                    <div className="deposit-bg">
                        <img src={earth} alt="hero-bg"/>
                    </div>
                    <div className="deposit-circle">
                        <AccountCircularProgressbar
                            value={calculatePercentage(accountBalance)}
                            text={`${accountBalance.toFixed(2)}$`}
                        />
                    </div>
                    <div className="deposit-status">
                        <span className="deposit-status-text">Статус:</span>
                        <span 
                            className="deposit-status-text"
                            style={{
                                color: accountBalance > 0 ? '#27AE60' : '#DE282C'
                            }}>
                            {accountBalance > 0 ? 'Активный' : 'Не активный'}
                        </span>
                    </div>
                    <DepositSecure/>
                </div>
            </div>
        </div>
    )
}

export default AccountDeposit