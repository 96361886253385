import { useState, useEffect } from 'react';
import AccountTitle from '../accountTitle/AccountTitle';
import TradingWidget from "../../widgets/tradingWidget/TradingWidget";
import { auth } from "../../../services/firebaseConfig";
import copy from "./../../../resources/images/icons/copy-color.png"
import './accountProfile.scss';

const AccountProfile = () => {
    const [currentValue, setCurrentValue] = useState(0);
    const [previousValue, setPreviousValue] = useState(null);
    const [color, setColor] = useState('');
    const [referralLink, setReferralLink] = useState("");
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            setReferralLink(`https://www.imperiumbroker.pro/registration?referrerId=${currentUser.uid}`);
        }
    }, []);
 
  
    useEffect(() => {
        const interval = setInterval(() => {
            const newValue = (Math.random() * (4 - 1.5) + 1.5).toFixed(3);
            setPreviousValue(currentValue);
            setCurrentValue(newValue);
        }, 2000);
  
        return () => clearInterval(interval);
    }, [currentValue]);
  
    useEffect(() => {
        if (previousValue !== null) {
            setColor(currentValue > previousValue ? '#27AE60' : '#DE282C');
        }
    }, [currentValue, previousValue]);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(referralLink)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 3000);
            })
            .catch(err => {
                console.error('Ошибка при копировании:', err);
            });
    };

    return (
        <div className="profile">
            <div className="container">
                <AccountTitle title='Мой кабинет' />
                <div className="profile-statistic">
                    <p className="profile-statistic-text">Средняя прибыль стейкинга в данный момент:</p>
                    <div 
                        className="profile-statistic-percent" 
                        style={{ color }}>
                        {currentValue} %
                    </div>
                </div>
                <div className="profile-referral">
                    <p className='profile-referral-title'>Ваша реферальная ссылка:</p>
                    <div className="profile-referral-link text">{referralLink}</div>
                    <button
                        className="profile-referral-copy"
                        onClick={handleCopyClick}
                    >
                        <span className={`profile-referral-copy-text ${isCopied ? 'copied' : ''}`}>
                            {isCopied ? 'Скопировано' : 'Копировать'}
                        </span>
                        <img src={copy} alt="copy"/>
                    </button>
                    <p className='profile-referral-descr text'>Скопируйте вашу реферальную ссылку и предоставьте её своему партнеру. 
                    Партнер сможет перейти по этой ссылке, чтобы открыть страницу регистрации.<br/>
                    Важно! Партнер должен завершить процесс регистрации на странице, открытой по вашей ссылке. 
                    Только после этого будет активирована ваша реферальная программа. Вы сможете получать 7% от суммы инвестиции каждого партнера, зарегистрировавшегося по вашей ссылке, и наслаждаться преимуществами заработка с партнерской программы.
                    </p>
                </div>
            </div>
            <TradingWidget />
        </div>
    );
}

export default AccountProfile;


